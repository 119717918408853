import axios from "./base";

//* home section
export const getImagesAPI = async () => {
  return []
}

// new
export const getBanner = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/banners?populate=*`, { __auth: false });
};

export const getVoucherImage = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/vouchers?populate=*`, { __auth: false });
}

export const getCategory = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/categories?populate=*`, { __auth: false });
}

export const getSaleTeam = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/salesmen?populate=*`, { __auth: false });
}

//

export const getProductColors = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/product-colors?populate=*`, { __auth: false });
}

export const getProductLengths = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/product-lengths?populate=*`, { __auth: false });
}

export const getProductStandards = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/product-standards?populate=*`, { __auth: false });
}

export const getProductTextures = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/product-textures?populate=*`, { __auth: false });
}

export const getProductQuality = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/product-qualities?populate=*`, { __auth: false });
}

//

export const getAllProduct = async (category2 = '', color = '', standard = '', length = '', texture = '', input = '', quality = '', sort = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/products?populate[images]=*&populate[category2]=*&populate[colors][populate]=*&populate[product_lengths]=*&populate[product_quality]=*&populate[product_standards]=*&populate[product_texture]=*&populate[product_origin]=*&filters[category2][name][$contains]=${encodeURI(category2)}&filters[colors][code][$contains]=${encodeURI(color)}&filters[product_standards][name][$contains]=${encodeURI(standard)}&filters[product_lengths][value][$contains]=${encodeURI(length)}&filters[product_texture][name][$contains]=${encodeURI(texture)}&filters[name][$contains]=${input}&sort[0]=${sort}`, { __auth: false });
}

export const getBestSellerProduct = async (category2 = '', color = '', standard = '', length = '', texture = '', input = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/products?populate[images]=*&populate[category2]=*&populate[colors][populate]=*&populate[product_lengths]=*&populate[product_quality]=*&populate[product_standards]=*&populate[product_texture]=*&populate[product_origin]=*&filters[category2][name][$contains]=${encodeURI(category2)}&filters[colors][code][$contains]=${encodeURI(color)}&filters[product_standards][name][$contains]=${encodeURI(standard)}&filters[product_lengths][value][$contains]=${encodeURI(length)}&filters[product_texture][name][$contains]=${encodeURI(texture)}&filters[name][$contains]=${input}&sort[0]=id:desc&filters[best_selling][$eq]=true`, { __auth: false });
}

export const getProductDetails = async (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/products/${id}?populate[images]=*&populate[category2]=*&populate[colors][populate]=*&populate[product_lengths]=*&populate[product_quality]=*&populate[product_standards]=*&populate[product_texture]=*&populate[product_origin]=*&populate[feedbacks]=*`);
}

// 

export const postFeedBack = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/feedbacks`, data);
}

export const getFeedBack = async (id = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/feedbacks?populate=*&filters[product][id][$eq]=${id}`);
}

// order

export const postOrder = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/orders`, data);
}

export const getOrderDetails = async (id) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/orders/?populate=*`, { data: { uuid: id } });
}

export const getOrderDetailsById = async (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/${id}?populate=*`);
}

export const getProductPrice = async (id = '', color = '', standard = '', length = '') => {
  const rcolor = color.replace("#", "%23")
  return axios.get(`${process.env.REACT_APP_API_URL}/product-prices?populate=*&filters[product][id][$eq]=${id}&filters[color_code][$eq]=${rcolor}&filters[product_standard][name][$eq]=${standard}&filters[product_length][value][$eq]=${length}`)
}

// ship 

export const getFedexZone = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/fedex-zones?populate=*`);
}

export const getFedexPrice = async (zone = '', weight = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/fedex-prices?populate=*&fields[1]=weightInKg&fields[0]=zone_${zone}&filters[weightInKg][$eq]=${weight}`);
}

export const getDHLZone = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/dhl-zones?populate=*`);
}

export const getDHLPrice = async (zone = '', weight = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/dhl-prices?populate=*&fields[1]=weightInKg&fields[0]=zone_${zone}&filters[weightInKg][$eq]=${weight}`);
}

export const getUPSZone = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/ups-zones?populate=*`);
}

export const getUPSPrice = async (zone = '', weight = '') => {
  return axios.get(`${process.env.REACT_APP_API_URL}/ups-prices?populate=*&fields[1]=weightInKg&fields[0]=zone_${zone}&filters[weightInKg][$eq]=${weight}`)
}

export const getBankInfo = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/bank-transfer`)
}

export const postContact = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/contacts`, data);
}

// dropdown
export const getProductForHomePageAPI = async (lang) => {
  return axios.get(`${process.env.REACT_APP_API_URL} / categories / product - types ? lang = ${lang}`, { __auth: false });
};
// best sale products
export const getBestSaleProductsAPI = async (lang) => {
  return axios.get(`${process.env.REACT_APP_API_URL} / products / best - selling ? lang = ${lang}`, { __auth: false });
};
// supporter
export const getSupportersAPI = async (pageSize, pageNumber) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL} / supporters / all - enabled ? pageSize = ${pageSize} & pageNumber=${pageNumber}`,
    { __auth: false }
  );
};

// subscriber
export const subscribeNewMemberAPI = async (email) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/subscribes`, email, { __auth: false });
};

