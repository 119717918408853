import { ACTION_TYPES } from "./actionType";

const initState = {
  parentCategory: null,
  childCategory: null,
  childItem: [],
  filter: {
    length: "",
    color: "",
    standard: "",
    texture: "",
    quality: ""
  },
  isloading: false
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CATEGORY:
      return {
        ...state,
        parentCategory: action.data.parentId,
        childCategory: action.data.childId,
        childItem: action.data.childItem,
        selectedFilter: action.data.selectedFilter
      }
    case ACTION_TYPES.GET_ALL_PRODUCT:
      return {
        ...state,
        products: action.data,
      };
    case ACTION_TYPES.FILTER:
      return {
        ...state,
        filter: action.data
      }
    case ACTION_TYPES.LOADING:
      return {
        ...state,
        isloading: action.data
      }
    case ACTION_TYPES.LOADINGOFF:
      return {
        ...state,
        isloading: false
      }
    default:
      return state
  }
};

export default reducer;
