import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  SlideFade,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ImageSlider from "@components/ImageSlider";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";
// slider
import { getErrorMessage } from "@api/handleApiError";
import { getBanner, getBestSellerProduct, getCategory, getSaleTeam, getVoucherImage } from "@api/main";
import ProductSlider from "@components/ProductSlider";
import SupporterCard from "@components/SupporterCard";
import useMobile from "@hooks/useMobile";
import { useLocation, useNavigate } from "react-router-dom";
import Widget from "@components/Widget";
import CategoryCard from "@components/CategoryCard";
import './Home.scss'
import { GridLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { handleSetCategory, SetLoading, SetLoadingOff } from "@store/action";

const Home = () => {
  const initLang = localStorage.getItem("language");
  const toast = useToast();
  const [isMobile] = useMobile();
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [supporterData, setSupporterData] = useState([]);
  const [slideImages, setSlideImages] = useState();
  const [voucherImage, setVoucherImage] = useState();
  const [category, setCategory] = useState()

  const query = useLocation().search;
  const payment = new URLSearchParams(query).get("payment");

  const dispatch = useDispatch()

  const getBestSeller = async () => {
    const res = await getBestSellerProduct()
    setProductsData(res.data)
  }

  const fetchDropDownData = async () => {
    const res = await getCategory();
    setCategory(res.data)
  };


  const fetchImageData = async () => {
    try {
      dispatch(SetLoading(true))
      const res = await getBanner();
      const voucherRes = await getVoucherImage()
      setSlideImages(res.data);
      setVoucherImage(voucherRes.data)

    } catch (error) {
      toast({
        title: "Api error",
        description: getErrorMessage(error),
        status: "error",
        duration: 1000,
      });
    } finally {
      dispatch(SetLoading(false))
    }
  };

  useEffect(() => {
    document.getElementById("navbar").style.top = "28px";
  }, [])

  useEffect(() => {
    fetchImageData();
    fetchDropDownData()
    getBestSeller()
    if (payment) {
      toast({
        description: "Thank you for purchased",
        status: "success",
        duration: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchSupporterData = async () => {
    try {
      const res = await getSaleTeam();
      setSupporterData(res.data);
    } catch (error) {
      toast({
        title: "Api error",
        description: getErrorMessage(error),
        status: "error",
        duration: 3000,
      });
    } finally {

    }
  };

  useEffect(() => {
    fetchSupporterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryClick = (parent) => {
    const formData = {
      parentId: parent.id,
      childId: parent.attributes.category2s.data[0].id,
      childItem: parent.attributes.category2s.data,
      selectedFilter: { parent: parent.attributes.name, child: parent.attributes.category2s.data[0].attributes.name, tabIndex: 0 }
    }
    dispatch(handleSetCategory(formData))
    navigate(`/products?id=${parent.attributes.category2s.data[0].id}`)
  }

  return (
    <>
      {voucherImage && <ImageSlider images={slideImages} promoteImage={voucherImage} />}
      <Container
        p={0}
        maxW={["100%", "100%", "100%", "100vw", "100%"]}
        // maxW={NavbarWidth} 
        mt={[0, 0, 0, 4, '16vh']}
        sx={{ minHeight: "90vh !important", position: "relative", }}
      // bg="black"
      >
        <Box>
          <BestSaleSection isMobile={isMobile} data={productsData} navigate={navigate} />
          <CategorySection onClick={handleCategoryClick} data={category} />
          {/* <BestSaleSection isMobile={isMobile} data={productsData} navigate={navigate} /> */}
          <VideoSection />

          {/* <AboutUsSection isMobile={isMobile} navigate={navigate} /> */}
          {/* <BestSaleSection isMobile={isMobile} data={productsData} navigate={navigate} /> */}
          <SupportSection isMobile={isMobile} data={supporterData} />

        </Box>
      </Container>
      <Widget />
    </>
  );
};


const CategorySection = ({ data, onClick }) => {
  const { ref, inView } = useInView();
  return (
    <Box mt={1} mb={5}>
      <Text
        fontSize={["20px", "20px", "20px", "25px", "25px"]}
        fontWeight="bold"
        textAlign="center"
        textTransform="uppercase"
        mb={2}
      // ml={10}
      // pt={3}
      >
        Category
      </Text>
      <Grid templateColumns="repeat(3,1fr)" bg="#000" pt={"1px"} pb={"1px"}>
        {data?.map((i, idx) => {
          return (
            <CategoryCard onClick={() => { onClick(i) }} key={idx} label={i.attributes.name} image={`${process.env.REACT_APP_IMAGE_URL}${i.attributes.picture.data.attributes.url}`} />
          )
        })}
      </Grid>
    </Box>
  )
}

const BestSaleSection = ({ isMobile, data, navigate }) => {
  const { ref, inView } = useInView();
  return (
    <SlideFade ref={ref} in={inView} offsetY="100px">
      <Box>
        <Box bg="#FFFF" p={isMobile ? 0 : 0}>
          <ProductSlider data={data} isMobile={isMobile} />
        </Box>
      </Box>
    </SlideFade>
  );
};

const VideoSection = () => {
  return (
    <div className="video-section">
      <Grid templateColumns="repeat(10,1fr)" h="100%">
        <GridItem colSpan={[10, 10, 10, 5, 5]}>
          <div className="video-container">
            <iframe className="video-player" src="https://www.youtube.com/embed/phWxA89Dy94"></iframe>
          </div>
        </GridItem>
        <GridItem colSpan={[10, 10, 10, 5, 5]}>
          <div className="right-title">
            About us
          </div>
          <Box className="right-content" fontSize={["10px", "10px", "10px", "16px", "16px"]} bg="#DFDFDF">
            <Text textAlign="justify">
              <FormattedMessage id="info.aboutUsSection" />
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </div>
  )
}

const AboutUsSection = ({ content, isMobile, navigate }) => {
  const { ref, inView } = useInView();
  return (
    <SlideFade ref={ref} in={inView} offsetY="100px">
      {!isMobile ? (
        <Flex bg="#F5F5F5" px={[10, 10, 10, 10, "50px"]} mb={5} justifyContent="center">
          <Flex mx="auto" justifyContent="center">
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <GridItem colSpan={1} display="flex" ml="auto">
                <Image src="/images/about_us_picture.png" />
              </GridItem>
              <GridItem colSpan={1} sx={{ mt: "auto", mb: "auto", display: "flex" }} maxW="690px">
                <Box>
                  <Box pb={[1, 2, 3, 5, 5]}>
                    <Text
                      fontSize={["20px", "20px", "20px", "40px", "40px"]}
                      fontWeight="bold"
                      textTransform="uppercase"
                      textAlign="center"
                    >
                      <FormattedMessage id="title.ourStory" defaultMessage="About us" />
                    </Text>
                    <Flex bg="black" w={97} h="3px" m="auto" />
                  </Box>
                  <Box fontSize={["10px", "10px", "10px", "16px", "16px"]} bg="#DFDFDF" p={7} borderRadius="17px">
                    <Text textAlign="justify">
                      <FormattedMessage id="info.aboutUsSection" />
                    </Text>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                    <Button
                      size={["sm", "sm", "sm"]}
                      onClick={() => {
                        navigate("/our-story");
                      }}
                      textTransform="none"
                      _hover={{ backgroundColor: "#D8D8D8" }}
                      sx={{ color: " #2D3748", borderColor: "#2D3748" }}
                    >
                      <FormattedMessage id="button.readMore" />
                    </Button>
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
      ) : (
        <Box bg="#F5F5F5" py={5}>
          <VStack spacing="24px">
            <Box>
              <Text fontSize="20px" fontWeight="bold" textTransform="uppercase" textAlign="center">
                <FormattedMessage id="title.ourStory" defaultMessage="About us" />
              </Text>
              <Flex bg="black" w={97} h="3px" m="auto" />
            </Box>
            <Image h="334px" px={10} pt={0} src="/images/about_us_picture.png" />
            <Text px={10} fontSize="10px" textAlign="justify">
              <FormattedMessage id="info.aboutUsSection" />
            </Text>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
              <Button
                onClick={() => {
                  navigate("/our-story");
                }}
                size="sm"
                textTransform="none"
                _hover={{ backgroundColor: "#D8D8D8" }}
                sx={{ color: " #2D3748", borderColor: "#2D3748" }}
              >
                <FormattedMessage id="button.readMore" />
              </Button>
            </Box>
          </VStack>
        </Box>
      )}
    </SlideFade>
  );
};

export const SupportSection = ({ isMobile, data }) => {
  const { ref, inView } = useInView();
  return (
    <Box bgImage="url('/backgrounds/support_background.png')">
      <SlideFade ref={ref} in={inView} offsetY="100px">
        <Box pb={5}>
          <Text
            pt={10}
            fontSize={["20px", "20px", "20px", "25px", "25px"]}
            fontWeight="bold"
            textAlign="center"
            textTransform="uppercase"
          >
            {/* <FormattedMessage id="label.supportOnline" /> */}
            Andrea's Sale Team
          </Text>
          {/* <Flex bg="black" w={97} h="3px" m="auto" /> */}
          <Grid mt={2} templateColumns={isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)"} >
            {data?.map((item, index) => {
              return (
                <GridItem
                  sx={{ display: "flex", justifyContent: "center" }}
                  key={index}
                  colSpan={1}
                  p={["5px", "20px", "20px", "20px", "50px"]}
                  pt={3}
                >
                  <SupporterCard
                    isMobile={isMobile}
                    image={`${process.env.REACT_APP_IMAGE_URL}${item?.attributes?.avatar.data[0].attributes.url}`}
                    name={item?.attributes.fullName}
                    email={item?.attributes.email}
                    fb={item?.attributes.facebook}
                    ig={item?.attributes.instagram}
                    whatsapp={item?.attributes.whatsApp}
                  />
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </SlideFade>
    </Box>
  );
};

export default Home;
