export const ACTION_TYPES = {
    SET_CATEGORY: "SET_CATEGORY",
    INCREASE_QUANTITY: "INCREASE_QUANTITY",
    DECREASE_QUANTITY: "DECREASE_QUANTITY",
    GET_ALL_PRODUCT: "GET_ALL_PRODUCT",
    GET_NUMBER_CART: "GET_NUMBER_CART",
    ADD_CART: "ADD_CART",
    UPDATE_CART: "UPDATE_CART",
    DELETE_CART: "DELETE_CART",
    REMOVE_CART: "REMOVE_CART",
    FILTER: "FILTER",
    LOADING: "LOADING",
    LOADINGOFF: "LOADINGOFF"
}