import { Image, Text } from '@chakra-ui/react'
import React from 'react'
import './CategoryCard.scss'

const CategoryCard = ({ label, image, onClick }) => {
    return (
        <div onClick={() => { onClick() }} className='category-card'>
            <div className='img-container'>
                <Image h={["160px", "120px", "150px", "500px", "620px", "746px"]} w={"100%"} src={!!image && image} className='category-image' />

                <div className='label-container'>
                    <Text fontSize={["12px", "12px", "12px", "20px", "20px"]} align="center" className='label'>{label}</Text>
                </div>
            </div>
        </div>
    )
}

export default CategoryCard