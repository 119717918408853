import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text, Link } from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { handleFilter, handleSetCategory } from "@store/action";

const ProductsAccordion = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleSubmenuClick = (parent, child) => {
    const array = parent.attributes.category2s.data.map((i) => {
      return i.id
    })
    const tabIndex = array.indexOf(child.id)
    const formData = {
      parentId: parent.id,
      childId: child.id,
      childItem: parent.attributes.category2s.data,
      selectedFilter: { parent: parent.attributes.name, child: child.attributes.name, tabIndex: tabIndex }
    }
    dispatch(handleSetCategory(formData))
    navigate(`/products?id=${child.id}`)
    dispatch(handleFilter({}))

    const eleTexture = document.querySelectorAll(".filter-box");
    const eleStandard = document.querySelectorAll(".quality-box");
    const eleColor = document.querySelectorAll(".color-wrapper");
    const eleLength = document.querySelectorAll(".length-box");

    eleTexture.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleStandard.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleColor.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleLength.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
  };

  const handleCategoryClick = (parent) => {
    const formData = {
      parentId: parent.id,
      childId: parent.attributes.category2s.data[0].id,
      childItem: parent.attributes.category2s.data,
      selectedFilter: { parent: parent.attributes.name, child: parent.attributes.category2s.data[0].attributes.name, tabIndex: 0 }
    }
    dispatch(handleSetCategory(formData))
    navigate(`/products?id=${parent.attributes.category2s.data[0].id}`)
    dispatch(handleFilter({}))

    const eleTexture = document.querySelectorAll(".filter-box");
    const eleStandard = document.querySelectorAll(".quality-box");
    const eleColor = document.querySelectorAll(".color-wrapper");
    const eleLength = document.querySelectorAll(".length-box");

    eleTexture.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleStandard.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleColor.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleLength.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
  }


  return (
    <Accordion borderBottom="none" borderTop="none" w="100%" allowMultiple>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <>
              <AccordionButton
                px={5}
                bg="#353535"
                borderBottom="1px solid #000000"
                sx={{ borderColor: "#000000 !important" }}
                h="50px"
              >
                <Box
                  fontSize="14px"
                  fontWeight="500"
                  textTransform="uppercase"
                  color="#FFEA85"
                  flex="1"
                  textAlign="left"
                >
                  <Link href="/products?search=true">
                    <FormattedMessage id="title.products" />
                  </Link>
                </Box>
                <Box color="#FFEA85">{isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}</Box>
              </AccordionButton>
            </>
            <AccordionPanel p={0}>
              <Box>
                <Accordion borderBottom="none" borderTop="none" allowToggle>
                  {data?.map((d, index) => {
                    return (
                      <AccordionItem key={index}>
                        {({ isExpanded }) => (
                          <>
                            <>
                              <AccordionButton px={5} pl={10} borderBottom="1px solid #000000" bg="#575757" h="43px">
                                <Box
                                  fontSize="14px"
                                  textTransform="uppercase"
                                  color="#FFEA85"
                                  flex="1"
                                  textAlign="left"
                                >
                                  <Text
                                    onClick={() => {
                                      handleCategoryClick(d);
                                    }}
                                  >
                                    {d.attributes.name}
                                  </Text>
                                </Box>
                                <Box color="#FFEA85">
                                  {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                                </Box>
                              </AccordionButton>
                            </>
                            <AccordionPanel p={0}>
                              {d?.attributes.category2s.data.map((c, a) => {
                                return (
                                  <Accordion borderBottom="none" borderTop="none" key={a}>
                                    <AccordionItem>
                                      <>
                                        <>
                                          <AccordionButton
                                            px={5}
                                            pl="60px"
                                            bg="#6B6E72"
                                            sx={{ borderColor: "#000000 !important" }}
                                            h="40px"
                                          >
                                            <Box
                                              fontSize="14px"
                                              textTransform="uppercase"
                                              color="#FFEA85"
                                              flex="1"
                                              textAlign="left"
                                            >
                                              <Text
                                                onClick={() => {
                                                  handleSubmenuClick(d, c);
                                                }}
                                              >
                                                {c.attributes.name}
                                              </Text>
                                            </Box>
                                          </AccordionButton>
                                        </>
                                      </>
                                    </AccordionItem>
                                  </Accordion>
                                );
                              })}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default ProductsAccordion;
