import { getAllProduct } from '@api/main';
import { Image, Text } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { useCallback } from 'react';
import { FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import './Searchbar.scss'

const SearchedItem = ({ title, price, subtitle, thumbImage, onClick }) => {
    const mainImage = `${process.env.REACT_APP_IMAGE_URL}${thumbImage}`
    return (
        <div className='search-item' onClick={() => { onClick() }}>
            <Image borderRadius={5} maxH={["80px", "90px", "100px", "90px", "100px"]} w={["80px", "80px", "80px", "90px", "100px"]} src={thumbImage ? mainImage : "/images/about_us_picture.png"} mr={3} />
            <div>
                <Text fontSize={"15px"} fontWeight="bold">
                    {title}
                </Text>
                <Text fontSize={"13px"} color="grey">
                    {subtitle}
                </Text>
                <Text>
                    {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(price ? price : 20)}
                </Text>
            </div>
        </div>
    )
}

const Searchbar = () => {
    const inputRef = useRef()
    const [open, setOpen] = useState(false)
    const [keyword, setKeyword] = useState("")
    const [data, setData] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [input, setInput] = useState(false)
    const navigate = useNavigate()

    const handleOpen = () => {
        setOpen(true)
        setInput(true)
        inputRef.current.focus()
    }

    const handleInputChange = (e) => {
        setKeyword(e.target.value)
        setData([])
    }

    const fetchData = useCallback(debounce(async (keyword) => {
        try {
            setIsloading(true)
            const res = await getAllProduct('', '', '', '', '', keyword)
            setData(res.data)
        } catch (error) {
            console.log(error);
        } finally {
            setIsloading(false)
        }

    }, 400), [])

    useEffect(() => {
        if (keyword !== '') { fetchData(keyword) }
    }, [keyword])

    const handleOnClick = (item) => {
        navigate(`/product/details?productId=${item.id}`);
    };

    return (

        <div className='search-wrapper' onBlur={() => { setInput(false) }}>
            <div style={{ cursor: "pointer" }} className='box' onClick={() => { handleOpen() }} >
                <input value={keyword} onChange={handleInputChange} ref={inputRef} type="text" className={input ? "input-focus" : "input"} name="txt" ></input>
                <i className={input ? "icon-focus" : "icon"}><FaSearch /></i>
            </div>

            {open && (
                <div className={keyword !== "" ? 'search-result-show' : 'search-result'}
                    onMouseLeave={() => { setOpen(false) }}>
                    {
                        isLoading ? <BarLoader loading={true} color="#d4af37" cssOverride={{ width: "100%" }} /> :
                            <div>
                                <div>
                                    {data.length > 0
                                        ?
                                        <>{data?.map((item, idx) => {
                                            return (
                                                <SearchedItem
                                                    key={idx}
                                                    thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                                                    title={item.attributes.name}
                                                    subtitle={item.attributes.category2.data.attributes.name}
                                                    onClick={() => {
                                                        handleOnClick(item);
                                                        setOpen(false)
                                                        setKeyword("")
                                                        setData([])
                                                    }}
                                                />
                                            )
                                        })}
                                        </>
                                        :
                                        <div>
                                            {keyword !== '' && <Text align="center">
                                                No matched product
                                            </Text>}

                                        </div>}
                                </div>

                            </div>
                    }
                </div>
            )}
        </div>
    )
}

export default Searchbar