import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import useMobile from "@hooks/useMobile";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import './ProductCard.scss'

const fakeData = {
  title: "Luxury Straight Light Color Double Layer Silky Flat Weft Hair",
  subtitle: "Curly hair",
  images: ['/images/about_us_picture.png', '/images/about_us_picture.png', '/images/about_us_picture.png', '/images/about_us_picture.png'],
  thumbImage: '/images/about_us_picture.png',
  price: '$25.00',
}

const ProductCard = ({ title, price, thumbImage, images, sx, onClick, isBestSelling, isView, h }) => {
  const [hoverImage, setHoverImage] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [isMobile] = useMobile()

  const mainImage = `${process.env.REACT_APP_IMAGE_URL}${thumbImage}`

  return (
    <Box
      className="product-card"
      onClick={() => {
        onClick();
      }}
      bg="#ffff"
      tabIndex={0}
      w={["95%", "182px", "182px", "97%", "99%"]}
      h={["260px", "290px", "290px", "315px", "350px"]}
      sx={{ ...sx, cursor: "pointer" }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <div className="img-container">
        <Image
          className="main-img"
          // src={hoverImage === null ? fakeData.thumbImage : fakeData.images[hoverImage]}
          src={thumbImage ? mainImage : "/images/about_us_picture.png"}
          alt="product"
          borderRadius={5}
          h={["200px", "290px", "290px", "260px", "290px"]}
          w={["260px", "290px", "290px", "301px", "301px"]}
        />
        {/* <div className="price-container">
          <div className="prod-price">{fakeData.price}</div>
        </div> */}
      </div>
      {isBestSelling && (
        <Box p={1} px={2} bg="red" top="10px" left="-3px" sx={{ position: "absolute" }}>
          <Text color="#ffff" fontSize="12px" fontWeight="500">
            <FormattedMessage id="label.bestSelling" />
          </Text>
        </Box>
      )}
      <Box mt={"2px"} className="product-content" borderRadius={5}>
        {isView && !isMobile && <div className="list-wrapper">
          <HStack className="list-img" spacing="0px" display={["none", "none", "none", "flex", "none"]}>
            {images?.slice(0, 3)?.map((image, index) => {
              return (
                <Image
                  borderRadius={3}
                  onMouseEnter={() => {
                    setHoverImage(index);
                  }}
                  onMouseLeave={() => {
                    setHoverImage(null);
                  }}
                  h="47px"
                  w="48px"
                  key={index}
                  src={image}
                />
              );
            })}
          </HStack>
        </div>}
        <VStack spacing="5px" px={1} display="block" >
          <Text
            // textTransform="uppercase"
            m="auto"
            fontSize={["12px", "12px", "12px", "15px", "15px"]}
            noOfLines={2}
            fontWeight="bold"
          >
            {title}
          </Text>
        </VStack>
        <Box sx={{ bottom: 0 }} mb={2} ml={1}>
          <Text textTransform="none" fontSize="14px">
            <div className="prod-price">
              {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(price)}
              {/* {Intl.NumberFormat("en-US").format(fakeData.price)} */}
            </div>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
