import { ACTION_TYPES } from "./actionType";

const initState = {
  numberCart: 0,
  carts: [],
  products: [],
}

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_NUMBER_CART:
      return {
        ...state,
      };
    case ACTION_TYPES.ADD_CART:
      if (state.numberCart === 0) {
        let cart = {
          productId: action.data.productId,
          productName: action.data.productName,
          image: action.data.image,
          price: action.data.price,
          quality: action.data.quality,
          length: action.data.length,
          color: action.data.color,
          quantity: action.data.quantity,
          amount: action.data.amount
        };
        state.carts.push(cart);

      } else {
        let check = false;
        state.carts.forEach((item, key) => {
          if (item.productId === action.data.productId) {
            state.carts[key].quantity = state.carts[key].quantity + action.data.quantity;
            check = true;
          }
        });
        if (!check) {
          var _cart = {
            ...state.carts,
            productId: action.data.productId,
            productName: action.data.productName,
            image: action.data.image,
            price: action.data.price,
            quantity: action.data.quantity,
            price: action.data.price,
            quality: action.data.quality,
            length: action.data.length,
            color: action.data.color,
            amount: action.data.amount
          };
          state.carts.push(_cart);
        }
      }
      return {
        ...state,
        numberCart: state.numberCart + 1,
      };
    case ACTION_TYPES.INCREASE_QUANTITY:
      // state.carts[action.data].quantity + 0.5;
      state.carts[action.data].quantity = state.carts[action.data].quantity + 0.1
      return {
        ...state,
      };
    case ACTION_TYPES.DECREASE_QUANTITY:
      let quantity = state.carts[action.data].quantity;
      if (quantity > 0) {
        state.carts[action.data].quantity = state.carts[action.data].quantity - 0.1;
      }

      return {
        ...state,
      };
    case ACTION_TYPES.DELETE_CART:
      let quantity_ = state.carts[action.data].quantity;
      // console.log(quantity_);
      return {
        ...state,
        numberCart: state.numberCart - 1,
        carts: state.carts.filter((item) => {
          return item.productId !== state.carts[action.data].productId;
        }),
      };
    case ACTION_TYPES.REMOVE_CART:
      return {
        ...state,
        carts: [],
        numberCart: 0,
      };
    default:
      return state
  }
};

export default cartReducer;
