import { getOrderDetailsById } from '@api/main'
import { Box, Container, Flex, Image, Text } from '@chakra-ui/react'
import Logo from '@components/header/Logo'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const OrderDetails = () => {
    const query = useLocation().search;
    const id = new URLSearchParams(query).get("uuid");
    const [data, setData] = useState()


    const fetchData = async () => {
        const res = await getOrderDetailsById(id)
        setData(res.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    let totalCartPrice = 0;

    data?.attributes.order_details.forEach((i) => {
        totalCartPrice = totalCartPrice + (i.amount * i.quantity);
    })

    return (
        <Container maxW={["100%", "100%", "600px", "600px", "600px"]}>
            <Box h="108px" bg={"#313130"} display="flex" justifyContent="center">
                <Image src={"/logo/AndreaHair_logo_2.svg"} />
            </Box>
            <Box mt={5} mb={5}>
                <Text fontWeight="bold">
                    Hello {data?.attributes.customer_full_name},
                </Text>
                <Text>
                    We've received you order and will contact you as soon as your package is shipped. You can find your purchase information bellow.
                </Text>
            </Box>
            <Box>
                <Text fontSize={"18px"} fontWeight="bold" borderBottom="3px solid black" mb={5}>
                    Order #{data?.attributes.uuid}
                </Text>
                {
                    data?.attributes.order_details.map((i, idx) => (
                        <Box key={idx}>
                            <Flex justifyContent="space-between">
                                <Text fontWeight="bold">
                                    Product: {i.product_name}
                                </Text>
                                <Image src={i.picture_url} sx={{ h: 100 }} />
                            </Flex>

                            <Box>
                                {/* <Flex justifyContent="space-between">
                                    <Text>
                                        Quality:
                                    </Text>
                                    <Text>
                                        {i.quality}
                                    </Text>
                                </Flex> */}
                                <Flex justifyContent="space-between">
                                    <Text fontWeight="bold">
                                        Length:
                                    </Text>
                                    <Text>
                                        {i.length}
                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <Text fontWeight="bold">
                                        Color:
                                    </Text>
                                    <Text>
                                        {i.color}
                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <Text fontWeight="bold">
                                        Standard:
                                    </Text>
                                    <Text>
                                        {i.standard}
                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <Text fontWeight="bold">
                                        Amount:
                                    </Text>
                                    <Text>
                                        {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.amount)}

                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <Text fontWeight="bold">
                                        Quantity
                                    </Text>
                                    <Text>
                                        {i.quantity}kg

                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between" mb={5}>
                                    <Text fontWeight="bold" >
                                        Total amount:
                                    </Text>
                                    <Text>
                                        {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.quantity * i.amount * 10)}
                                    </Text>
                                </Flex>
                            </Box>
                        </Box>
                    ))
                }
                <Box borderTop="3px solid black">
                    <Flex justifyContent="space-between" mt={5}>
                        <Text fontWeight="bold">
                            Total amount:
                        </Text>
                        <Text>
                            {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(data?.attributes.amount)}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Discount:
                        </Text>
                        <Text>
                            {data?.attributes.discount}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Delivery fee:
                        </Text>
                        <Text>
                            {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(data?.attributes.delivery_rate)}

                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb={5}>
                        <Text fontWeight="bold">
                            Paid:
                        </Text>
                        <Text>
                            {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(data?.attributes.paid)}

                        </Text>
                    </Flex>
                </Box>
                <Box borderTop="3px solid black">
                    <Flex justifyContent="space-between" mt={5}>
                        <Text fontWeight="bold">
                            Full name:
                        </Text>
                        <Text>
                            {data?.attributes.customer_full_name}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Phone number:
                        </Text>
                        <Text>
                            {data?.attributes.phone_number}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Email:
                        </Text>
                        <Text>
                            {data?.attributes.email}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Address:
                        </Text>
                        <Text>
                            {data?.attributes.address}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            City:
                        </Text>
                        <Text>
                            {data?.attributes.city}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Delivery method:
                        </Text>
                        <Text>
                            {data?.attributes.delivery_method}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold">
                            Delivery address:
                        </Text>
                        <Text>
                            {data?.attributes.delivery_address}
                        </Text>
                    </Flex>
                </Box>
            </Box>
            <Box>

            </Box>
        </Container >
    )
}

export default OrderDetails