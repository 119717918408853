import { Box, Flex, HStack, Link, Stack, Text } from "@chakra-ui/react";
import DropDownSubmenu from "@components/DropDownSubmenu";
import React from "react";
import { FormattedMessage } from "react-intl";

const NavbarItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link className="navbar-item" href={to} sx={{ textDecoration: "none" }}>
      <Flex
        alignContent="center"
        justifyContent="center"
        fontSize="18px"
        fontWeight="600"
        textTransform="uppercase"
        display="block"
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

const NavbarItems = ({ isOpen, dropDownData }) => {
  const signUpElement = document.getElementById("sign-up-section");

  const handleScroll = () => {
    signUpElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      display={[isOpen ? "block" : "none", isOpen ? "block" : "none", isOpen ? "block" : "none", "block", "block"]}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={12}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <NavbarItem to="/">
          {/* <FormattedMessage id="title.contact" /> */}
          <FormattedMessage id="title.main" />
        </NavbarItem>

        <DropDownSubmenu
          data={dropDownData}
          title={
            <Flex
              alignContent="center"
              justifyContent="center"
              fontSize="18px"
              fontWeight="600"
              textTransform="uppercase"
            //   display="block"
            >
              <Link href="/products?search=true">
                <HStack className="navbar-item" justifyContent="center">
                  <Text
                    sx={{ cursor: "pointer" }}
                  >
                    <FormattedMessage id="title.products" />
                  </Text>
                </HStack>
              </Link>
            </Flex>
          }
        />

        <NavbarItem to="/our-story">
          <FormattedMessage id="title.ourStory" />
        </NavbarItem>

        <Flex
          className="navbar-item"
          onClick={() => {
            handleScroll();
          }}
          alignContent="center"
          justifyContent="center"
          fontSize="18px"
          fontWeight="600"
          textTransform="uppercase"
          display="block"
          sx={{ cursor: "pointer" }}
        >
          <FormattedMessage id="title.subscribe" />
        </Flex>
      </Stack>
    </Box>
  );
};

export default NavbarItems;
