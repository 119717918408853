import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Navbar from "./header/Navbar";

import "react-whatsapp-chat-widget/index.css";
import LocaleHeader from "./header/LocaleHeader";
import useMobile from "@hooks/useMobile";
import NavbarM from "./header/NavbarM";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
// icon

const MainLayout = () => {
  const [isMobile] = useMobile();
  const reduxState = useSelector((state) => state)

  return (
    <>
      <LocaleHeader isMobile={isMobile} />
      {isMobile ? <NavbarM /> : <Navbar />}
      {reduxState.reducer.isloading === true && <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, background: "#eee", opacity: "0.9", textAlign: "center", zIndex: 10 }}>
        <HashLoader loading={true} size={60} color="#d4af37" cssOverride={{ display: 'flex', marginTop: "23%", width: "auto !important", height: "auto !important" }} />
      </div>}
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
