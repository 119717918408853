import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'

import { MdClose } from 'react-icons/md'
import './Filter.scss'
import 'react-accessible-accordion/dist/fancy-example.css';
import { Grid, Select, Text, VStack } from '@chakra-ui/react';
import ColorCard from './ColorCard';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCheckbox from './CustomCheckbox';
import FilterItem from './FilterItem';
import { LengthEnum, LengthEnumDisplay, TextureEnum, TextureEnumDisplay } from '@utility/constant';
import { enumToSelectOptions } from '@utility/ultils';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { handleFilter } from '@store/action';

export const FilterMobile = ({ data }) => {
    const dispatch = useDispatch()
    const reduxState = useSelector((state) => state)
    const [sortBy, setSortBy] = useState()
    const filter = reduxState && reduxState.reducer.filter
    const visible = filter?.length || filter?.standard || filter?.color || filter?.texture || filter?.quality

    const handleCloseFilter = () => {
        document.getElementById('filter').style.width = "0"
        document.getElementById('backdrop').style.display = 'none'
    }

    const handleTextureFilter = (e, value) => {
        const ele = document.querySelectorAll(".filter-box");
        ele.forEach((e) => {
            e.setAttribute('aria-selected', 'false')
        })
        e.target.setAttribute('aria-selected', 'true')
        dispatch(handleFilter({ ...filter, texture: value }))
    }

    const handleStandardFilter = (e, value) => {
        const ele = document.querySelectorAll(".quality-box");
        ele.forEach((e) => {
            e.setAttribute('aria-selected', 'false')
        })
        e.target.setAttribute('aria-selected', 'true')
        dispatch(handleFilter({ ...filter, standard: value }))
    }

    const handleColorFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".color-wrapper");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, color: value }))
    }

    const handleLengthFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".length-box");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, length: value }))
    }

    const handleQualityFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".quality-filter");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, quality: value }))
    }

    const handleRemoveFilter = (type, value) => {
        if (type === "sort") {
            const eleSort = document.querySelectorAll(".filter-item");
            eleSort.forEach(function (btn) {
                btn.setAttribute('aria-selected', 'false');
            });
            setSortBy()
        }
        if (type === "color") {
            const eleColor = document.querySelectorAll(".color-wrapper");
            eleColor.forEach((btn) => {
                btn.setAttribute('aria-selected', 'false');
            })
            dispatch(handleFilter({ ...filter, color: null }))
        }
        if (type === "texture") {
            const ele = document.querySelectorAll(".filter-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, texture: null }))
        }
        if (type === "standard") {
            const ele = document.querySelectorAll(".quality-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, standard: null }))
        }
        if (type === "length") {
            const ele = document.querySelectorAll(".length-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, length: null }))
        }
        if (type === "quality") {
            const ele = document.querySelectorAll(".quality-filter");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, quality: null }))
        }
    }

    return (
        <>
            <div onClick={() => { handleCloseFilter() }} id="backdrop" className='backdrop'>
            </div>
            <div id="filter" className='filter-section'>
                <div className='filter-header'>
                    <div className='text-bold'>
                        Filter & Sort
                    </div>
                    <div onClick={() => {
                        handleCloseFilter()
                    }}>
                        <MdClose size={30} />
                    </div>
                </div>
                {visible &&
                    <div className='apply-container'>
                        <div style={{ paddingLeft: "18px", fontWeight: "bold", marginBottom: "10px" }}>
                            Applied filters
                        </div>
                        <AppliedFilter
                            sortBy={sortBy}
                            color={filter.color}
                            texture={filter.texture}
                            standard={filter.standard}
                            length={filter.length}
                            quality={filter.quality}
                            handleRemoveFilter={handleRemoveFilter}
                        />
                    </div>
                }
                <Scrollbars style={{ height: "90vh" }}>
                    <div className='filter-body'>
                        <Accordion allowZeroExpanded >
                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton >
                                        Sort By
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <VStack alignItems="flex-start" spacing={0}>
                                        <div onClick={(e) => { handleChangeFilter(e, "Newest") }} className='filter-item' aria-selected="false">
                                            Newest
                                        </div>
                                        <div onClick={(e) => { handleChangeFilter(e, "Price (HIGH-LOW)") }} className='filter-item' aria-selected="false">
                                            Price (HIGH-LOW)
                                        </div>
                                        <div onClick={(e) => { handleChangeFilter(e, "Price (LOW-HIGH)") }} className='filter-item' aria-selected="false">
                                            Price (LOW-High)
                                        </div>
                                        <div onClick={(e) => { handleChangeFilter(e, "Best Sellers") }} className='filter-item' aria-selected="false">
                                            Best Sellers
                                        </div>
                                    </VStack>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Quality
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='section-filter' style={{ paddingLeft: "18px" }}>
                                        {data?.quality?.data.map((v, index) => (
                                            <FilterItem className="quality-filter" key={index} onClick={handleQualityFilter} label={v.attributes.name} value={v.id} />
                                        ))}

                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Standard
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='section-filter' style={{ paddingLeft: "18px" }}>
                                        {data?.standards?.data.map((v, index) => (
                                            <FilterItem className="quality-box" key={index} onClick={handleStandardFilter} label={v.attributes.name} value={v.id} />
                                        ))}

                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Texture
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='section-filter' style={{ paddingLeft: "18px" }}>
                                        {data?.textures.data.map((v, index) => (
                                            <FilterItem key={index} onClick={handleTextureFilter} label={v.attributes.name} value={v.id} />
                                        ))}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Length
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='section-filter' style={{ paddingLeft: "18px" }}>
                                        {data?.lengths.data.map((v, index) => {
                                            return (
                                                <FilterItem className="length-box" key={index} onClick={handleLengthFilter} label={v.attributes.value} value={v.id} />
                                            )
                                        })}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Color
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className='section-filter' style={{ paddingLeft: "18px" }}>
                                        {data?.colors.data.map((v, index) => {
                                            return (
                                                <ColorCard image={v.attributes.image.data.attributes.url} color={v.attributes.code} key={index} onClick={handleColorFilter} />
                                            )
                                        })}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div >
                </Scrollbars>
                <div className='filter-action'>
                    <button className='btn-apply'>Apply</button>
                </div>
            </div >
        </>
    )
}

const AppliedFilter = ({ sortBy, color, handleRemoveFilter, texture, standard, length, quality }) => {

    const FilterTag = ({ value, label, onRemove }) => {
        return (
            <div className='filter-tag'>
                <MdClose size={20} onClick={() => { onRemove() }} />
                <span >

                    {label ? label : value}
                </span>
            </div>
        )
    }
    return (
        <div className='applied-filter'>
            {sortBy ? <div>
                <FilterTag value={sortBy} onRemove={() => { handleRemoveFilter("sort") }} />
            </div> : <div></div>}
            {texture ?
                <FilterTag value={texture.value} label={texture.label} onRemove={() => { handleRemoveFilter("texture", texture) }} ></FilterTag>
                : <div></div>
            }
            {
                color ?
                    <FilterTag value={color} label={color} onRemove={() => { handleRemoveFilter("color", color) }} />
                    : <div></div>
            }
            {standard ?
                <FilterTag value={standard.value} label={standard.label} onRemove={() => { handleRemoveFilter("standard", standard) }} ></FilterTag>
                : <div></div>
            }
            {length ?
                <FilterTag value={length.value} label={length.label} onRemove={() => { handleRemoveFilter("length", standard) }} ></FilterTag>
                : <div></div>
            }
            {
                quality ? <FilterTag value={quality.value} label={quality.label} onRemove={() => { handleRemoveFilter("quality", quality) }} ></FilterTag>
                    : <div></div>
            }
        </div >
    )
}

export const Filter = ({ sx, sort, setSort, data }) => {
    const dispatch = useDispatch()
    const reduxState = useSelector((state) => state)
    const [sortBy, setSortBy] = useState()
    const filter = reduxState && reduxState.reducer.filter
    const visible = filter?.length || filter?.standard || filter?.color || filter?.texture || filter?.quality

    const handleSort = (e) => {
        setSort(e.target.value)
    }

    const handleTextureFilter = (e, value) => {
        const ele = document.querySelectorAll(".filter-box");
        ele.forEach((e) => {
            e.setAttribute('aria-selected', 'false')
        })
        e.target.setAttribute('aria-selected', 'true')
        dispatch(handleFilter({ ...filter, texture: value }))
    }

    const handleStandardFilter = (e, value) => {
        const ele = document.querySelectorAll(".quality-box");
        ele.forEach((e) => {
            e.setAttribute('aria-selected', 'false')
        })
        e.target.setAttribute('aria-selected', 'true')
        dispatch(handleFilter({ ...filter, standard: value }))
    }

    const handleColorFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".color-wrapper");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, color: value }))
    }

    const handleLengthFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".length-box");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, length: value }))
    }

    const handleQualityFilter = (e, value) => {
        const eleColor = document.querySelectorAll(".quality-filter");
        eleColor.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
        })
        e.target.setAttribute('aria-selected', 'true');
        dispatch(handleFilter({ ...filter, quality: value }))
    }

    const handleRemoveFilter = (type, value) => {
        if (type === "sort") {
            const eleSort = document.querySelectorAll(".filter-item");
            eleSort.forEach(function (btn) {
                btn.setAttribute('aria-selected', 'false');
            });
            setSortBy()
        }
        if (type === "color") {
            const eleColor = document.querySelectorAll(".color-wrapper");
            eleColor.forEach((btn) => {
                btn.setAttribute('aria-selected', 'false');
            })
            dispatch(handleFilter({ ...filter, color: null }))
        }
        if (type === "texture") {
            const ele = document.querySelectorAll(".filter-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, texture: null }))
        }
        if (type === "standard") {
            const ele = document.querySelectorAll(".quality-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, standard: null }))
        }
        if (type === "length") {
            const ele = document.querySelectorAll(".length-box");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, length: null }))
        }
        if (type === "quality") {
            const ele = document.querySelectorAll(".quality-filter");
            ele.forEach((e) => {
                e.setAttribute('aria-selected', 'false')
            })
            dispatch(handleFilter({ ...filter, quality: null }))
        }
    }
    return (
        <>
            <div className='filter-body' style={sx}>
                {visible &&
                    <div className='apply-container'>
                        <div className='text-bold'>
                            Applied filters
                        </div>
                        <AppliedFilter
                            sortBy={sortBy}
                            color={filter.color}
                            texture={filter.texture}
                            standard={filter.standard}
                            length={filter.length}
                            quality={filter.quality}
                            handleRemoveFilter={handleRemoveFilter}
                        />
                    </div>
                }

                <div>
                    <div className='section'>
                        <Text fontWeight="bold" p={1}>
                            Sort By
                        </Text>
                        <Select value={sort} placeholder='Default' w="60%" onChange={handleSort}>
                            <option value="default_price:desc">
                                Price (HIGH-LOW)
                            </option>
                            <option value="default_price:asc">
                                Price (LOW-HIGH)
                            </option>
                            <option value="name:asc">
                                A-Z
                            </option>
                            <option value="name:desc">
                                Z-A
                            </option>
                        </Select>
                    </div>
                </div>
                <div className='section'>
                    <Text fontWeight="bold" p={1}>
                        Quality
                    </Text>
                    <div className='section-filter'>
                        {data?.quality?.data.map((v, index) => (
                            <FilterItem className="quality-filter" key={index} onClick={handleQualityFilter} label={v.attributes.name} value={v.id} />
                        ))}

                    </div>
                </div>
                <div className='section'>
                    <Text fontWeight="bold" p={1}>
                        Standard
                    </Text>
                    <div className='section-filter'>
                        {data?.standards?.data.map((v, index) => (
                            <FilterItem className="quality-box" key={index} onClick={handleStandardFilter} label={v.attributes.name} value={v.id} />
                        ))}

                    </div>
                </div>
                <div className='section'>
                    <Text fontWeight="bold" p={1}>
                        Color
                    </Text>
                    <div className='section-filter'>
                        {data?.colors.data.map((v, index) => {
                            return (
                                <ColorCard image={v.attributes.image.data.attributes.url} color={v.attributes.code} key={index} onClick={handleColorFilter} />
                            )
                        })}
                    </div>
                </div>
                <div className='section'>
                    <Text fontWeight="bold" p={1}>
                        Texture
                    </Text>
                    <div className='section-filter'>
                        {data?.textures.data.map((v, index) => (
                            <FilterItem key={index} onClick={handleTextureFilter} label={v.attributes.name} value={v.id} />
                        ))}
                    </div>
                </div>
                <div className='section'>
                    <Text fontWeight="bold" p={1}>
                        Length
                    </Text>
                    <div className='section-filter'>
                        {data?.lengths.data.map((v, index) => {
                            return (
                                <FilterItem className="length-box" key={index} onClick={handleLengthFilter} label={v.attributes.value} value={v.id} />
                            )
                        })}

                    </div>
                </div>
            </div>

        </>
    )
}