export const ResponsiveLogoConfig = ["50px", "50px", "50px", "50px"];

export const ResponsiveNavbarHeight = ["60px", "60px", "70px", "70px"];

export const ResponsiveImage = ["200px", "200px", "400px", "87vh", "700px"];

export const ResponsiveBanner = ["200px", "200px", "400px", "63vh", "650px"];

export const ResponsiveBanner2 = ["200px", "200px", "400px", "25vh", "20vh"];

export const NavbarWidth = ["100%", "100%", "100%", "98vw", "100%"]