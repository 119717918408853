import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import ProductCard from "@components/ProductCard/ProductCard";
import ProductCardCustom from "@components/ProductCard/ProductCardCustom";
import useTablet from "@hooks/useTablet";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
// slick
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { NavbarWidth } from "src/theme/ResponsiveTheme";
import './ProductSlider.scss'

const ProductSlider = ({ data, isMobile }) => {
  const slider = useRef()
  const navigate = useNavigate();
  const [isTablet] = useTablet();

  const handleOnClick = (item) => {
    navigate(`/product/details?productId=${item.id}`);
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div style={{ cursor: "pointer" }}>
        <ChevronLeftIcon
          // className={className}
          onClick={onClick}
          _hover={{ color: "black" }}
          sx={{}}
          boxSize={8}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ChevronRightIcon
        // className={className}
        _hover={{ color: "black" }}
        onClick={onClick}
        // sx={{ ...style, display: isMobile ? "none" : isTablet ? "none" : "block" }}
        boxSize={8}
      />
    );
  };

  const ButtonN = (props) => {
    const { className, style, onClick } = props;
    return (
      <ChevronRightIcon
        // className={className}
        _hover={{ color: "black" }}
        onClick={onClick}
        sx={{ display: 'none' }}
        boxSize={8}
      />
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <ButtonN />,
    nextArrow: <ButtonN />,
    responsive: [
      {
        breakpoint: 914,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  var mobileSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <ButtonN />,
    nextArrow: <ButtonN />,
    // width: '100%'
  };

  var tabletSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  // console.log(slider.current['slickPrev']);
  return (
    <Box py={[6, 6, 6, 0, 0]} w="100%" >
      <div className="slider-tool">
        {!isMobile &&
          <div style={{ width: "5%" }}>

          </div>}
        <Text
          fontSize={["20px", "20px", "20px", "25px", "25px"]}
          fontWeight="bold"
          textAlign="center"
          textTransform="uppercase"
        // position="absolute"
        // w="100%"
        // ml={10}
        // pt={3}
        >
          <FormattedMessage id="label.bestSelling" />
        </Text>

        {!isMobile && !!slider.current && <div className="btn-container">
          <PrevArrow onClick={slider.current['slickPrev']} />
          <NextArrow onClick={slider.current['slickNext']} />
        </div>}
      </div>

      {isMobile ? (
        <Slider {...mobileSettings}>
          {data?.map((item, index) => {
            return (
              <ProductCardCustom
                sx={{
                  mb: 1,
                  mx: "auto",
                }}
                isView
                key={index}
                // isBestSelling={item.isBestSelling}
                title={item.attributes.name}
                thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                // images={item.imageUrls}
                // subtitle={item.productTypeName}
                price={item.attributes.default_price}
                onClick={() => {
                  handleOnClick(item);
                }}
              />
            );
          })}
        </Slider>
      ) : isTablet ? (
        <Slider {...tabletSettings}>
          {data?.map((item, index) => {
            return (
              <ProductCardCustom
                sx={{
                  mb: 1,
                  mx: "auto",
                }}
                isView
                key={index}
                // isBestSelling={item.isBestSelling}
                title={item.attributes.name}
                thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                // images={item.imageUrls}
                // subtitle={item.productTypeName}
                price={item.attributes.default_price}
                onClick={() => {
                  handleOnClick(item);
                }}
              />
            );
          })}
        </Slider>
      ) : (
        <Slider ref={slider}{...settings}>
          {data?.map((item, index) => {
            return (
              <ProductCardCustom
                sx={{
                  mb: 1,
                  mx: "auto",
                }}
                isView
                key={index}
                // isBestSelling={item.isBestSelling}
                title={item.attributes.name}
                thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                // images={item.imageUrls}
                // subtitle={item.productTypeName}
                price={item.attributes.default_price}
                onClick={() => {
                  handleOnClick(item);
                }}
              />
            );
          })}
        </Slider>
      )}
    </Box>
  );
};

export default ProductSlider;
