export const OrderByTypeEnum = {
  Asc: 0,
  Desc: 1,
};

export const HairStyleEnum = {
  Straight: 1,
  KinkyStraight: 2,
  Wave: 3,
  BodyWave: 4,
  LooseWay: 5,
  DeepWave: 6,
  Curly: 7,
  CurlyWave: 8,
  KinkyCurly: 9,
};

export const HairStyleDisplayConfig = {
  [HairStyleEnum.Straight]: "Straight",
  [HairStyleEnum.KinkyStraight]: "KinkyStraight",
  [HairStyleEnum.Wave]: "Wave",
  [HairStyleEnum.BodyWave]: "BodyWave",
  [HairStyleEnum.LooseWay]: "LooseWay",
  [HairStyleEnum.DeepWave]: "DeepWave",
  [HairStyleEnum.Curly]: "Curly",
  [HairStyleEnum.CurlyWave]: "CurlyWave",
  [HairStyleEnum.KinkyCurly]: "KinkyCurly",
};

export const MaterialTypeEnum = {
  RemyHair: 1,
  VirginHair: 2,
};

export const MaterialTypeDisplayConfig = {
  [MaterialTypeEnum.RemyHair]: "RemyHair",
  [MaterialTypeEnum.VirginHair]: "VirginHair",
};

export const LengthMeasureUnitEnum = {
  Inch: 1,
  Centimeter: 2,
};

export const LengthMeasureUnitDisplayConfig = {
  [LengthMeasureUnitEnum.Inch]: "Inch",
  [LengthMeasureUnitEnum.Centimeter]: "Centimeter",
};

export const WeightMeasureUnitEnum = {
  Kilogram: 1,
  Pound: 2,
};

export const WeightMeasureUnitDisplayConfig = {
  [WeightMeasureUnitEnum.Kilogram]: "Kilogram",
  [WeightMeasureUnitEnum.Pound]: "Pound",
};

export const PackingRuleEnum = {
  ClearPlasticBagWithSmallTag: 1,
  ClientsPackingAndLogoAccepted: 2,
};

export const PackingRuleDisplayConfig = {
  [PackingRuleEnum.ClearPlasticBagWithSmallTag]: "ClearPlasticBagWithSmallTag",
  [PackingRuleEnum.ClientsPackingAndLogoAccepted]: "ClientsPackingAndLogoAccepted",
};

export const TextureEnum = {
  BodyWavy: 1,
  BouncyWavy: 2,
  DeepCurly: 3,
  DeepWavy: 4,
  FumiCurly: 5,
  FumiWavy: 6,
  KinkyCurly: 7,
  KinkyStraight: 8,
  LooseCurly: 9,
  LooseWavy: 10,
  NaturalWavy: 11,
  RomanticCurly: 12,
  Straight: 13,
  TwistCurly: 14,
  WaterBodyWavy: 15,
  YakiStraight: 16,
}

export const TextureEnumDisplay = {
  [TextureEnum.BodyWavy]: "Body Wavy",
  [TextureEnum.BouncyWavy]: "Bouncy Wavy",
  [TextureEnum.DeepCurly]: "Deep Curly",
  [TextureEnum.DeepWavy]: "Deep Wavy",
  [TextureEnum.FumiCurly]: "Fumi Curly",
  [TextureEnum.FumiWavy]: "Fumi Wavy",
  [TextureEnum.KinkyCurly]: "Kinky Curly",
  [TextureEnum.KinkyStraight]: "Kinky Straight",
  [TextureEnum.LooseCurly]: "Loose Curly",
  [TextureEnum.LooseWavy]: "Loose Wavy",
  [TextureEnum.NaturalWavy]: "Natural Wavy",
  [TextureEnum.RomanticCurly]: "Romantic Curly",
  [TextureEnum.Straight]: "Straight",
  [TextureEnum.TwistCurly]: "Twist Curly",
  [TextureEnum.WaterBodyWavy]: "Water Body Wavy",
  [TextureEnum.YakiStraight]: "Yaki Straight"
};

export const LengthEnum = {
  inch06: 1,
  inch08 : 2,
  inch10 : 3,
  inch12 : 4,
  inch14 : 5,
  inch16 : 6,
  inch18 : 7,
  inch20 : 8,
  inch22 : 9,
  inch24 : 10,
  inch26 : 11,
  inch28 : 12,
  inch30 : 13,
  inch32 : 14
}

export const LengthEnumDisplay = {
  [LengthEnum.inch06]: "06 inch",
  [LengthEnum.inch08]: "08 inch",
  [LengthEnum.inch10]: "10 inch",
  [LengthEnum.inch12]: "12 inch",
  [LengthEnum.inch14]: "14 inch",
  [LengthEnum.inch16]: "16 inch",
  [LengthEnum.inch18]: "18 inch",
  [LengthEnum.inch20]: "20 inch",
  [LengthEnum.inch22]: "22 inch",
  [LengthEnum.inch24]: "24 inch",
  [LengthEnum.inch26]: "26 inch",
  [LengthEnum.inch28]: "28 inch",
  [LengthEnum.inch30]: "30 inch",
  [LengthEnum.inch32]: "32 inch",
};