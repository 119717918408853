import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//
import './Dropdown.scss'
import { useDispatch } from "react-redux";
import { handleFilter, handleSetCategory } from "@store/action";

const DropDownSubmenu = ({ title, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleSubmenuClick = (parent, child) => {
    const array = parent.attributes.category2s.data.map((i) => {
      return i.id
    })
    const tabIndex = array.indexOf(child.id)
    const formData = {
      parentId: parent.id,
      childId: child.id,
      childItem: parent.attributes.category2s.data,
      selectedFilter: { parent: parent.attributes.name, child: child.attributes.name, tabIndex: tabIndex }
    }
    dispatch(handleSetCategory(formData))
    navigate(`/products?id=${child.id}`)
    dispatch(handleFilter({}))
    setOpen(false)

    const eleTexture = document.querySelectorAll(".filter-box");
    const eleStandard = document.querySelectorAll(".quality-box");
    const eleColor = document.querySelectorAll(".color-wrapper");
    const eleLength = document.querySelectorAll(".length-box");

    eleTexture.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleStandard.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleColor.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleLength.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
  };

  const handleCategoryClick = (parent) => {
    const formData = {
      parentId: parent.id,
      childId: parent.attributes.category2s.data[0].id,
      childItem: parent.attributes.category2s.data,
      selectedFilter: { parent: parent.attributes.name, child: parent.attributes.category2s.data[0].attributes.name, tabIndex: 0 }
    }
    dispatch(handleSetCategory(formData))
    navigate(`/products?id=${parent.attributes.category2s.data[0].id}`)
    setOpen(false)
    dispatch(handleFilter({}))

    const eleTexture = document.querySelectorAll(".filter-box");
    const eleStandard = document.querySelectorAll(".quality-box");
    const eleColor = document.querySelectorAll(".color-wrapper");
    const eleLength = document.querySelectorAll(".length-box");

    eleTexture.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleStandard.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleColor.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })

    eleLength.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
  }

  const onHoverMouse = () => {
    setOpen(true)
  }


  return (
    <div
      className="dropdown-wrapper"
    >
      <div className="dropdown-button" onMouseEnter={() => { onHoverMouse() }}>{title}</div>
      {open && <div className="dropdown-content" id="main-dropdown">
        {data.map((d, index) => (
          <div key={index} className="parent-container">
            <div className="parent" onClick={() => { handleCategoryClick(d) }}>
              {d.attributes.name}
            </div>
            {
              d.attributes.category2s && <div className="child-wrapper">
                <div className="container-1">
                  {d.attributes.category2s.data?.map((c, index) => {
                    return (
                      <div key={index} className="child-item-1" onClick={() => {
                        handleSubmenuClick(d, c)
                      }}>
                        {c.attributes.name}
                      </div>
                    )
                  })}
                </div>
              </div>
            }
          </div>
        ))}
      </div>}
    </div >
  );
};

export default DropDownSubmenu;
