import {
    Box,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Flex,
    GridItem,
    Grid,
    Button,
    VStack,
    HStack,
    useToast,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { CgMail, CgPhone } from "react-icons/cg";
import { ImLocation2 } from "react-icons/im";
import { Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useMobile from "@hooks/useMobile";
import SupporterCard from "@components/SupporterCard";
import { FormProvider, RHFInput } from "@components/hook-form";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMessage } from "@api/handleApiError";
import { postContact } from "@api/main";

const ContactSection = () => {
    const [isMobile] = useMobile();

    const intl = useIntl();
    const toast = useToast();

    const defaultValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
    };
    const ContactSchema = yup.object().shape({
        firstName: yup.string().required().max(256),
        lastName: yup.string().required().max(256),
        email: yup.string().email().required().max(256),
        phoneNumber: yup.string().required(),
        message: yup.string().required(),
    });

    const methods = useForm({
        mode: "all",
        defaultValues,
        resolver: yupResolver(ContactSchema),
    });

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        reset
    } = methods;

    const onSubmit = async (data) => {
        const formData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            message: data.message,
        };
        try {
            await postContact({ data: formData });
            toast({
                title: "Success",
                description: intl.formatMessage({ id: "toast.messageContactSuccess" }),
                status: "success",
                duration: 1000,
            });
            reset()
        } catch (error) {
            toast({
                title: "Api error",
                description: getErrorMessage(error),
                status: "error",
                duration: 1000,
            });
        }
    };
    return (
        <div style={!isMobile ? { paddingRight: "150px", paddingLeft: "150px", backgroundColor: "#eeeeee", paddingBottom: "10px", paddingTop: "10px" } : {}}>
            <Box mt={5} mb={5}>
                <Text
                    fontSize={["20px", "20px", "20px", "25px", "25px"]}
                    fontWeight="bold"
                    color="#000000"
                    textAlign="center"
                    textTransform="uppercase"
                >
                    <FormattedMessage id="label.contactUs" />
                </Text>
                <Text
                    fontSize={["12px", "12px", "12px", "16px", "16px"]}
                    fontWeight="regular"
                    color="#000000"
                    textAlign="center"
                    textTransform="uppercase"
                >
                    <FormattedMessage id="label.any" />
                </Text>
            </Box>
            {!isMobile ? (
                <Flex bg="#FFFFFF" my={2}>
                    <Grid templateColumns="repeat(10, 1fr)" w="100%">
                        <GridItem colSpan={4} bg="url('/backgrounds/contact_2.jpg')">
                            <Box sx={{ p: 6, pl: 10 }}>
                                <Text
                                    fontSize={["16px", "24px", "24px", "24px", "24px"]}
                                    fontWeight="bold"
                                    color="#FFFFFF"
                                    textTransform="uppercase"
                                >
                                    <FormattedMessage id="label.contactInformation" />
                                </Text>
                                <Text fontSize={["15px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                    <FormattedMessage id="label.saySomething" />
                                </Text>
                                <VStack alignItems="flex-start" mt={5} spacing={4}>
                                    <HStack mt={10}>
                                        <CgPhone style={{ color: "#FFFF", height: "19px", width: "19px" }} />
                                        <Text fontSize={["6px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.phoneNumber" />
                                        </Text>
                                    </HStack>
                                    <HStack mt={10}>
                                        <CgMail style={{ color: "#FFFF", height: "22px", width: "22px" }} />
                                        <Text fontSize={["6px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.email" />
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <ImLocation2 style={{ color: "#FFFF", height: "22px", width: "22px" }} />
                                        <Text fontSize={["6px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.address" />
                                        </Text>
                                    </HStack>
                                </VStack>
                            </Box>
                        </GridItem>
                        <GridItem w="100%" colSpan={6} sx={{ mt: "auto", mb: "auto", display: "flex" }}>
                            <Box w="100%" p={6} py={6}>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid templateColumns="repeat(2,1fr)" gap={10}>
                                        <GridItem colSpan={1}>
                                            <Box>
                                                <Text>
                                                    <FormattedMessage id="label.firstName" />
                                                </Text>
                                                <RHFInput name="firstName" variant="flushed" />
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Box>
                                                <Text>
                                                    <FormattedMessage id="label.lastName" />
                                                </Text>
                                                <RHFInput name="lastName" variant="flushed" />
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Box>
                                                <Text>
                                                    <FormattedMessage id="label.email" />
                                                </Text>
                                                <RHFInput name="email" variant="flushed" />
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Box>
                                                <Text>
                                                    <FormattedMessage id="label.phoneNumber" />
                                                </Text>
                                                <RHFInput name="phoneNumber" variant="flushed" />
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={2}>
                                            <Box>
                                                <Text>
                                                    <FormattedMessage id="label.message" />
                                                </Text>
                                                <RHFInput
                                                    placeholder={intl.formatMessage({ id: "placeholder.message" })}
                                                    name="message"
                                                    variant="flushed"
                                                />
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Box sx={{ display: "flex", mt: 10 }}>
                                        <Button
                                            _hover={{ boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);" }}
                                            disabled={!isDirty || !isValid}
                                            type="submit"
                                            ml="auto"
                                            p={3}
                                            bg="#000000"
                                            variant="solid"
                                            fontWeight="500"
                                            textTransform="none"
                                        >
                                            <FormattedMessage id="button.sendMessage" />
                                        </Button>
                                    </Box>
                                </FormProvider>
                            </Box>
                        </GridItem>
                    </Grid>
                </Flex>
            ) : (
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                        <GridItem colSpan={1} sx={{ mt: "auto", mb: "auto", display: "flex" }} maxW="690px">
                            <Box w="100%" p={6} py={10}>
                                <Grid templateColumns="repeat(2,1fr)" gap={10}>
                                    <GridItem colSpan={2}>
                                        <Box>
                                            <Text>
                                                <FormattedMessage id="label.firstName" />
                                            </Text>
                                            <RHFInput name="firstName" variant="flushed" />
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Box>
                                            <FormattedMessage id="label.lastName" />
                                            <Text></Text>
                                            <RHFInput name="lastName" variant="flushed" />
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Box>
                                            <Text>
                                                <FormattedMessage id="label.email" />
                                            </Text>
                                            <RHFInput name="email" variant="flushed" />
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Box>
                                            <Text>
                                                <FormattedMessage id="label.phoneNumber" />
                                            </Text>
                                            <RHFInput name="phoneNumber" variant="flushed" />
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Box>
                                            <Text>
                                                <FormattedMessage id="label.message" />
                                            </Text>
                                            <RHFInput
                                                placeholder={intl.formatMessage({ id: "placeholder.message" })}
                                                name="message"
                                                variant="flushed"
                                            />
                                        </Box>
                                    </GridItem>
                                </Grid>
                                <Box sx={{ display: "flex", mt: 10, justifyContent: "center" }}>
                                    <Button
                                        _hover={{ boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);" }}
                                        disabled={!isDirty || !isValid}
                                        type="submit"
                                        p={6}
                                        bg="#000000"
                                        variant="solid"
                                        fontWeight="500"
                                        textTransform="none"
                                    >
                                        <FormattedMessage id="button.sendMessage" />
                                    </Button>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={1} bg="url('/backgrounds/contact_2.jpg')">
                            <Box sx={{ p: 10 }}>
                                <Text
                                    fontSize={["22px", "24px", "24px", "24px", "24px"]}
                                    fontWeight="bold"
                                    color="#FFFFFF"
                                    textTransform="uppercase"
                                >
                                    <FormattedMessage id="label.contactInformation" />
                                </Text>
                                <Text fontSize={["15px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                    <FormattedMessage id="label.saySomething" />
                                </Text>
                                <VStack alignItems="flex-start" mt={5} spacing={4}>
                                    <HStack mt={10}>
                                        <CgPhone style={{ color: "#FFFF", height: "19px", width: "19px" }} />
                                        <Text fontSize={["15px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.phoneNumber" />
                                        </Text>
                                    </HStack>
                                    <HStack mt={10}>
                                        <CgMail style={{ color: "#FFFF", height: "22px", width: "22px" }} />
                                        <Text fontSize={["15px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.email" />
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <ImLocation2 style={{ color: "#FFFF", height: "22px", width: "22px" }} />
                                        <Text fontSize={["15px", "14px", "14px", "14px", "14px"]} fontWeight="regular" color="#FFFFFF">
                                            <FormattedMessage id="info.address" />
                                        </Text>
                                    </HStack>
                                </VStack>
                            </Box>
                        </GridItem>
                    </Grid>
                </FormProvider>
            )}</div>
    )
}

export default ContactSection