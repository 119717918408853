import { Box, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { TiShoppingCart } from "react-icons/ti";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BadgeStyle = {
  position: "absolute",
  bottom: "15px",
  borderRadius: "30%",
  background: "#d4af37",
  color: "white",
  fontSize:"10px",
  padding: "3px 7px 0px 7px",
  right: "22px",
  fontWeight:"bold"
}

const Cart = () => {
  const navigate = useNavigate()
  const cartQuantity = useSelector((state) => state.cartReducer.numberCart)
  return (
    <Menu>
      <MenuButton onClick={() => {
        navigate("/cart")
      }} >
        <TiShoppingCart size={30} />
        {!!cartQuantity && <div style={BadgeStyle}>{cartQuantity}</div>}
      </MenuButton>
    </Menu>
  );
};

export default Cart;
