import { Grid, GridItem, HStack, Radio, RadioGroup, Select, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { FaFedex, FaDhl, FaUps } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { isEmpty } from 'lodash'
import { getBankInfo, getDHLPrice, getDHLZone, getFedexPrice, getFedexZone, getUPSPrice, getUPSZone, postOrder } from '@api/main'
import { useDispatch } from 'react-redux'
import { handleRemoveCartAC } from '@store/action'
import ContactModal from '@views/Products/ContactModal'

const CartStep2 = ({ setStep, cartState, goToStep3 }) => {
    const paypal = useRef();
    const toast = useToast();
    const initData = {
        firstName: "",
        lastName: "",
        country: "",
        postCode: "",
        phoneNumber: "",
        address: "",
        city: "",
        email: "",
        apt: ""
    }
    const errorData = {
        firstName: false,
        lastName: false,
        country: false,
        phoneNumber: false,
        address: false,
        city: false,
        email: false,
    }

    let totalCartPrice = 0;

    cartState.carts?.forEach((i) => {
        totalCartPrice = totalCartPrice + (i.amount * i.quantity) * 10;
    });

    let totalWeight = 0;

    cartState.carts?.forEach((i) => {
        totalWeight = totalWeight + i.quantity;
    });

    if (totalWeight < 0.5) {
        totalWeight = 0.5
    }

    let myBtn = window.paypal
        .Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: cartItem
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();

                console.log("success", order);
                setTransactionStatus("success");
            },
            onError: (err) => {
                console.log(err);
                setTransactionStatus("failure");
            },
        })

    const [formInfo, setFormInfo] = useState(initData)
    const [errors, setErrors] = useState(errorData)
    const [errorDetails, setErrorDetails] = useState(errorData)
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [shipMethod, setShipMethod] = useState("fedex")
    const [paymentMethod, setPaymentMethod] = useState('trans')

    const [fedexZone, setFedexZone] = useState([])
    const [selectedFedexZone, setSelectedFedexZone] = useState(0)

    const [dhlZone, setDhlZone] = useState([])
    const [selectedDHLZone, setSelectedDHLZone] = useState(0)

    const [upsZone, setUpsZone] = useState([])
    const [selectedUPSZone, setSelectedUPSZone] = useState(0)

    const [shipCost, setShipCost] = useState(0)

    const [status, setStatus] = useState(false)

    const [bankInfo, setBankInfo] = useState()
    const [shipZone, setShipZone] = useState()

    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const cartItem = [{ reference_id: `payment`, amount: { currency_code: "USD", value: ((totalCartPrice + shipCost) + ((totalCartPrice + shipCost) * 8.5 / 100)).toFixed(2) } }]

    const fetchBankInfo = async () => {
        const res = await getBankInfo()

        setBankInfo(res.data)
    }

    useEffect(() => {
        fetchBankInfo()
    }, [])

    const fetchShipingData = async () => {
        if (shipMethod === "fedex") {
            const res = await getFedexZone()
            setFedexZone(res.data)
        }
        if (shipMethod === "dhl") {
            const res = await getDHLZone()
            setDhlZone(res.data)
        }
        if (shipMethod === "ups") {
            const res = await getUPSZone()
            setUpsZone(res.data)
        }
    }

    useEffect(() => {
        fetchShipingData()
    }, [shipMethod])

    const fetchPrice = async () => {
        var fedexZoneCode
        var dhlZoneCode
        var upsZoneCode
        if (fedexZone.length > 0) {
            for (var i = 0; i <= fedexZone.length; i++) {
                if (fedexZone[i]?.attributes.countriesAndTerritories === selectedFedexZone) {
                    fedexZoneCode = fedexZone[i]?.attributes.zoneCode
                }
            }
        }
        if (dhlZone.length > 0) {
            for (var i = 0; i <= dhlZone.length; i++) {
                if (dhlZone[i]?.attributes.countriesAndTerritories === selectedDHLZone) {
                    dhlZoneCode = dhlZone[i]?.attributes.zoneCode
                }
            }
        }
        if (upsZone.length > 0) {
            for (var i = 0; i <= upsZone.length; i++) {
                if (upsZone[i]?.attributes.countriesAndTerritories === selectedUPSZone) {
                    upsZoneCode = upsZone[i]?.attributes.zoneCode
                }
            }
        }

        if (shipMethod === "fedex" && selectedFedexZone) {
            const res = await getFedexPrice(fedexZoneCode, (Math.round((totalWeight + 0.3) * 2) / 2).toFixed(1))
            const price = res.data[0].attributes[`zone_${fedexZoneCode}`]

            setShipCost(price)
            setShipZone(`zone_${fedexZoneCode}`)
        }
        if (shipMethod === "dhl" && selectedDHLZone) {
            const res = await getDHLPrice(dhlZoneCode, (Math.round((totalWeight + 0.3) * 2) / 2).toFixed(1))
            const price = res.data[0].attributes[`zone_${dhlZoneCode}`]

            setShipCost(price)
            setShipZone(`zone_${dhlZoneCode}`)
        }
        if (shipMethod === "ups" && selectedUPSZone) {
            const res = await getUPSPrice(upsZoneCode, (Math.round((totalWeight + 0.3) * 2) / 2).toFixed(1))
            const price = res.data[0].attributes[`zone_${upsZoneCode}`]

            setShipCost(price)
            setShipZone(`zone_${upsZoneCode}`)
        }
    }

    console.log(shipZone);

    useEffect(() => {
        fetchPrice()
    }, [selectedFedexZone, selectedDHLZone, selectedUPSZone])

    const handleShippingMethod = (e) => {
        setShipMethod(e)
        setSelectedDHLZone(0)
        setSelectedFedexZone(0)
        setSelectedUPSZone(0)
        setFormInfo({ ...formInfo, country: '' })
        setStatus(false)
        setPaymentMethod('trans')
        setShipCost(0)
    }


    const handleChangeInput = (e, type) => {
        const field = type !== "country" ? e.target.name : "country"
        switch (field) {
            case "firstName":
                setFormInfo({ ...formInfo, firstName: e.target.value })
                break;
            case "lastName":
                setFormInfo({ ...formInfo, lastName: e.target.value })
                break;
            case "address":
                setFormInfo({ ...formInfo, address: e.target.value })
                break;
            case "postCode":
                setFormInfo({ ...formInfo, postCode: e.target.value })
                break;
            case "country":
                setFormInfo({ ...formInfo, country: e.target.value })
                break;
            case "city":
                setFormInfo({ ...formInfo, city: e.target.value })
                break;
            case "email":
                setFormInfo({ ...formInfo, email: e.target.value })
                break;
            case "apt":
                setFormInfo({ ...formInfo, apt: e.target.value })
                break;
            case "phoneNumber":
                setFormInfo({ ...formInfo, phoneNumber: e.target.value })
                break;
            default:
                break;
        }
    }

    const validateData = () => {
        const testPhoneNumber = () => {
            return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/.test(formInfo.phoneNumber)
        }
        const testEmail = () => {
            return /\S+@\S+\.\S+/.test(formInfo.email);
        }
        var error = {}
        if (formInfo.address === "") {
            error = { ...error, address: true }
        }
        if (formInfo.firstName === "") {
            error = { ...error, firstName: true }
        }
        if (formInfo.lastName === "") {

            error = { ...error, lastName: true }
        }
        if (formInfo.country === '') {
            error = { ...error, country: true }
        }
        if (formInfo.city === "") {
            error = { ...error, city: true }
        }
        if (formInfo.phoneNumber === "") {
            error = { ...error, phoneNumber: true }
        } else {
            if (!testPhoneNumber()) {
                error = { ...error, phoneNumber: "failed" }
            }
        }
        if (formInfo.email === "") {
            error = { ...error, email: true }
        } else {
            if (!testEmail()) {
                error = { ...error, email: "failed" }
            }
        }
        setErrorDetails(error)
    }

    useEffect(() => {
        validateData()
    }, [formInfo])

    console.log();

    const ErrorMessage = ({ label }) => {
        return (
            <Text fontSize="sm" color="red">{label}</Text>
        )
    }

    const TurnOnPaypay = (type) => {
        const a = isEmpty(errorDetails)
        setErrors(errorDetails)
        if (type === "pay") {
            if (a && shipCost > 0) {
                myBtn.render(paypal.current)
                setStatus(true)
            }
        }
        if (type === "contact") {
            if (a) {
                setOpen(true)
            }
        }

    }

    useEffect(() => {
        if (paymentMethod === "trans") {
            myBtn.close()
        }
        if (paymentMethod === "paypal") {
            myBtn.render(paypal.current)
        }

    }, [paymentMethod])

    const ALLITEM = cartState.carts.map((i) => {
        return {
            product_id: i.productId,
            product_name: i.productName,
            color: i.color,
            length: i.length.label,
            standard: i.quality.label,
            quantity: i.quantity,
            amount: (Number(i.amount) * Number(i.quantity) * 10).toFixed(2),
            picture_url: i.image
        }
    })

    const createOrder = async () => {
        const formData = {
            customer_full_name: `${formInfo.firstName} ${formInfo.lastName}`,
            email: formInfo.email,
            phone_number: formInfo.phoneNumber,
            address: `${formInfo.address} ${formInfo.apt}`,
            city: formInfo.city,
            country: formInfo.country,
            delivery_address: formInfo.address,
            payment_method: paymentMethod,
            delivery_method: shipMethod,
            delivery_zone: String(shipZone),
            amount: totalCartPrice + shipCost,
            discount: 0,
            delivery_rate: shipCost,
            paid: totalCartPrice + shipCost,
            status: "Waiting Verify Payment",
            order_details: ALLITEM,
            uuid: "",
            paypal_fee: ((totalCartPrice + shipCost) * 8.5 / 100)
        }
        try {
            if (formInfo.country === '') {
                validateData()

                setErrors(errorDetails)
            } else {
                const res = await postOrder({
                    data: formData
                })
                setTransactionStatus(null)
                dispatch(handleRemoveCartAC())
                toast({
                    description: "Thank you for purchase",
                    status: "success",
                    duration: 1000,
                });
                goToStep3(res.data.id)
            }
        } catch (error) {
            toast({
                // description: error,
                status: "error",
                duration: 1000,
            });
            setTransactionStatus(null)
        }
    }

    if (transactionStatus === "success") {
        createOrder()
    }

    useEffect(() => {
        if (shipCost === 0) {
            setStatus(false)
        }
    }, [shipCost])

    return (
        <div>
            <button className='btn-back' onClick={() => {
                setStep(0)
            }}>
                <MdOutlineKeyboardBackspace />
                <Text textTransform="none">
                    <FormattedMessage id="btn-back-cart" />
                </Text>
            </button>
            <Grid templateColumns={["repeat(2,1fr)", "repeat(2,1fr)", "repeat(10,1fr)", "repeat(10,1fr)", "repeat(10,1fr)"]} gap={[0, 0, 0, 10, 10]}>
                <GridItem colSpan={6} onClick={() => { setErrors(errorData) }}>
                    <Text fontSize={18} pt={5} ml={[3, 3, 3, 0, 0]} mb={2} fontWeight="500" borderTop="3px solid #eee">
                        <FormattedMessage id="shoppingInfo" />
                    </Text>

                    <div className='shipping-section' style={{ marginBottom: "20px" }}>
                        <RadioGroup onChange={handleShippingMethod} value={shipMethod}>
                            <HStack gap={10}>
                                <Radio value="fedex"><FaFedex style={{ width: "50px", height: "50px", color: "blue" }} /></Radio>
                                <Radio value="dhl"><FaDhl style={{ width: "50px", height: "50px", color: 'orange' }} /></Radio>
                                <Radio value="ups"><FaUps style={{ width: "50px", height: "50px", color: 'orange' }} /></Radio>
                            </HStack>
                        </RadioGroup>
                        <div className='detail-section'>
                            <div style={{ marginBottom: "20px" }}>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="country" /> <span className='red' style={{ color: "red" }}>*</span>
                                </Text>
                                {shipMethod === "fedex" && <Select value={selectedFedexZone} onChange={(e) => { setSelectedFedexZone(e.target.value); handleChangeInput(e, "country"); }} >
                                    <option value={0}>Select ...</option>
                                    {fedexZone?.map((i, idx) => {
                                        return (<option key={idx} value={i.attributes.countriesAndTerritories}>{i.attributes.countriesAndTerritories}</option>)
                                    })}
                                </Select>}
                                {shipMethod === "dhl" && <Select value={selectedDHLZone} onChange={(e) => { setSelectedDHLZone(e.target.value); handleChangeInput(e, "country") }}>
                                    <option value={0}>Select ...</option>
                                    {dhlZone?.map((i, idx) => {
                                        return (<option key={idx} value={i.attributes.countriesAndTerritories}>{i.attributes.countriesAndTerritories}</option>)
                                    })}
                                </Select>}
                                {shipMethod === "ups" && <Select value={selectedUPSZone} onChange={(e) => { setSelectedUPSZone(e.target.value); handleChangeInput(e, "country") }}>
                                    <option value={0}>Select ...</option>
                                    {upsZone?.map((i, idx) => {
                                        return (<option key={idx} value={i.attributes.countriesAndTerritories}>{i.attributes.countriesAndTerritories}</option>)
                                    })}
                                </Select>}
                                {errors.country && <ErrorMessage label="This field is required" />}
                            </div>
                        </div>
                    </div>

                    <Grid templateColumns={"repeat(8,1fr)"} gap={6} fontSize={14} pl={[0, 0, 0, 10, 10]} p={[3, 3, 3, 0, 0]}>


                        <GridItem colSpan={4}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="city" /> <span className='red'>*</span>
                                </Text>
                                <input
                                    name="city"
                                    className='custom-input'
                                    value={formInfo.city}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                                {errors.city && <ErrorMessage label="This field is required" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="zip" />
                                </Text>
                                <input
                                    name="postCode"
                                    className='custom-input'
                                    value={formInfo.postCode}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                            </div>
                        </GridItem>
                        <GridItem colSpan={5}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="address" /> <span className='red'>*</span>
                                </Text>
                                <input
                                    name='address'
                                    className='custom-input'
                                    value={formInfo.address}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                                {errors.address && <ErrorMessage label="This field is required" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={3}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="apt" />
                                </Text>
                                <input
                                    name="apt"
                                    className='custom-input'
                                    value={formInfo.apt}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                            </div>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="label.firstName" /><span className='red'>*</span>
                                </Text>
                                <input
                                    name="firstName"
                                    className='custom-input'
                                    value={formInfo.firstName}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                                {errors.firstName && <ErrorMessage label="This field is required" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="label.lastName" /> <span className='red'>*</span>
                                </Text>
                                <input
                                    name="lastName"
                                    className='custom-input'
                                    value={formInfo.lastName}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                                {errors.lastName && <ErrorMessage label="This field is required" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={8}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="label.phoneNumber" /> <span className='red'>*</span>
                                </Text>
                                <input
                                    name="phoneNumber"
                                    className='custom-input'
                                    value={formInfo.phoneNumber}
                                    onChange={(e) => { handleChangeInput(e) }}
                                ></input>
                                {errors.phoneNumber === true && <ErrorMessage label="This field is required" />}
                                {errors.phoneNumber === "failed" && <ErrorMessage label="Improperly formatted" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={8}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="label.email" /> <span className='red'>*</span>
                                </Text>
                                <input
                                    value={formInfo.email}
                                    onChange={(e) => { handleChangeInput(e) }}
                                    name="email"
                                    type="email"
                                    className='custom-input'
                                ></input>
                                {errors.email === true && <ErrorMessage label="This field is required" />}
                                {errors.email === "failed" && <ErrorMessage label="Improperly formatted" />}
                            </div>
                        </GridItem>
                        <GridItem colSpan={8}>
                            <div className='input-container'>
                                <Text fontWeight="bold" fontSize={15}>
                                    <FormattedMessage id="order-note" />
                                </Text>
                                <textarea
                                    value={formInfo.note}
                                    onChange={(e) => { handleChangeInput(e) }}
                                    name="note"
                                    className='order-note'
                                    rows={3}
                                ></textarea>
                            </div>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem colSpan={4}>
                    <div className='checkout-section'>
                        <div className='subtotal-container'>
                            <Text fontWeight="500" fontSize={18} textTransform="uppercase">Your order</Text>
                        </div>
                        <div className='checkout-product'>
                            <div className='subtotal-container product'>
                                <Text fontWeight="bold">
                                    <FormattedMessage id="label.products" />
                                </Text>
                                <Text fontWeight="bold">
                                    <FormattedMessage id="subtotal" />
                                </Text>
                            </div>
                            {
                                cartState.carts?.map((i, idx) => (
                                    <div className='subtotal-container item'>
                                        <div className='desciption' >
                                            <Text>
                                                {i.productName} x {i.quantity.toFixed(1)}kg
                                            </Text>
                                            <Text>
                                                <FormattedMessage id="standard" />: {i?.quality.label}
                                            </Text>
                                            <Text>
                                                <FormattedMessage id="label.length" />: {i?.length.label}
                                            </Text>
                                            <Text>
                                                <FormattedMessage id="label.color" />: {i?.color}
                                            </Text>
                                        </div>
                                        <Text fontSize={14} >
                                            {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.amount * i.quantity * 10)}
                                        </Text>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='subtotal-container' >
                            <Text fontWeight="bold">
                                <FormattedMessage id="subtotal" />:
                            </Text>
                            <Text fontWeight="bold">
                                {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(totalCartPrice)}
                            </Text>
                        </div>
                        <div className='subtotal-container' >
                            <Text fontWeight="bold">
                                <FormattedMessage id="discount" />:
                            </Text>
                            <Text fontWeight="bold" color="#8c9aa9">
                                $0
                            </Text>
                        </div>
                        <div className='subtotal-container' >
                            <Text fontWeight="bold">
                                <FormattedMessage id="shipping" />:
                            </Text>
                            <Text fontWeight="bold" color="#8c9aa9">
                                {shipCost > 0 ? <>{Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(shipCost)} </> : 'Contact'}
                            </Text>
                        </div>
                        {
                            paymentMethod === "paypal" &&
                            <div className='subtotal-container' >
                                <Text fontWeight="bold">
                                    Paypal Fee:
                                </Text>
                                <Text fontWeight="bold" color="#8c9aa9">
                                    {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format((totalCartPrice + shipCost) * 8.5 / 100)}
                                </Text>
                            </div>
                        }

                        <div className='grand-total'>
                            <Text>
                                <FormattedMessage id="total" />:
                            </Text>
                            <Text fontWeight="bold">
                                {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(paymentMethod === "trans" ? (totalCartPrice + shipCost) : (totalCartPrice + shipCost) + ((totalCartPrice + shipCost) * 8.5 / 100))}
                            </Text>
                        </div>
                        {!status && shipCost > 0 && <button button className='btn-paypal' onClick={() => { TurnOnPaypay("pay") }}>
                            <Text fontSize={24} fontWeight="bold" color="#FFF">Payment</Text>
                        </button>}
                        {
                            !status && shipCost === 0 && <button className='btn-paypal' onClick={() => { TurnOnPaypay("contact") }}>
                                <Text fontSize={24} fontWeight="bold" color="#FFF">Contact</Text>
                            </button>
                        }
                        {status && <>
                            <RadioGroup mb={5} onChange={(e) => { setPaymentMethod(e) }} value={paymentMethod}>
                                <HStack gap={10}>
                                    <Radio value="trans">Transfer</Radio>
                                    <Radio value="paypal">Paypal</Radio>
                                </HStack>
                            </RadioGroup></>
                        }
                        {
                            status && paymentMethod === "paypal" &&
                            <div>
                                <div ref={paypal}></div>
                            </div>
                        }
                        {
                            status && paymentMethod === "trans" &&
                            <div>
                                <div className='subtotal-container' >
                                    <Text >
                                        Account number:
                                    </Text>
                                    <Text  >
                                        {bankInfo.attributes.account_number}
                                    </Text>
                                </div>
                                <div className='subtotal-container' >
                                    <Text >
                                        Account name:
                                    </Text>
                                    <Text  >
                                        {bankInfo.attributes.account_name}
                                    </Text>
                                </div>
                                <div className='subtotal-container' >
                                    <Text >
                                        Bank name:
                                    </Text>
                                    <Text  >
                                        {bankInfo.attributes.bank_name}
                                    </Text>
                                </div>
                                <div className='subtotal-container' >
                                    <Text >
                                        Content:
                                    </Text>
                                    <Text  >
                                        {bankInfo.attributes.free_text}
                                    </Text>
                                </div>
                                <button className='btn-paypal' onClick={() => { createOrder() }}>
                                    <Text fontSize={24} fontWeight="bold" color="#FFF">Place order</Text>
                                </button>
                            </div>
                        }
                    </div>
                </GridItem>
            </Grid>
            {
                open && <ContactModal isOpen={open} onClose={() => { setOpen(false) }} />
            }
        </div >
    )
}

export default CartStep2