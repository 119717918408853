import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import ProductCard from "@components/ProductCard";
// import ProductFilter from "@components/ProductFilter";
import useMobile from "@hooks/useMobile";
import { useEffect, useState } from "react";
// icon

// paging
import { getErrorMessage } from "@api/handleApiError";
import { getAllProduct, getProductColors, getProductLengths, getProductQuality, getProductStandards, getProductTextures, getSaleTeam } from "@api/main";

import { useLocation, useNavigate } from "react-router-dom";
// 
import './Product.scss'
import { Filter, FilterMobile } from "@components/ProductFilter/Filter";
import { FaFilter } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux";
import { SupportSection } from "@views/Home/Home";
import { SetLoading } from "@store/action";

const Products = () => {
  // redux
  const reduxState = useSelector((state) => state)
  const dispatch = useDispatch()
  // hooks
  const [isMobile] = useMobile();
  const navigate = useNavigate()
  const toast = useToast();

  const query = useLocation().search;
  const search = new URLSearchParams(query).get("search");
  // filter
  const [selectedProductType, setSelectedProductType] = useState();
  const [tab, setTab] = useState(0)
  // product data
  const [products, setProducts] = useState([]);
  const [filterOptions, setFilterOptions] = useState()
  const [supporterData, setSupporterData] = useState([]);
  const [sort, setSort] = useState('')

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    scrollToTop();
    document.getElementById("navbar").style.position = "absolute";
    document.getElementById("navbar").style.top = "28px";
    setTab(reduxState && reduxState.reducer.selectedFilter?.tabIndex)
    if (!search) { setSelectedProductType(reduxState && reduxState.reducer.selectedFilter.child) }

  }, [reduxState.reducer.selectedFilter]);

  const fetchFilterOptions = async () => {
    const length = await getProductLengths()
    const color = await getProductColors()
    const texture = await getProductTextures()
    const standard = await getProductStandards()
    const quality = await getProductQuality()
    const obj = {
      lengths: length,
      colors: color,
      textures: texture,
      standards: standard,
      quality: quality
    }
    setFilterOptions(obj)
  }

  useEffect(() => {
    fetchFilterOptions()
  }, [])

  const fetchData = async (selectedProductType) => {
    const { length, color, standard, texture, quality } = reduxState.reducer.filter
    const _length = length ? length.label : ""
    const _color = color ? color : ""
    const _standard = standard ? standard.label : ""
    const _texture = texture ? texture.label : ""
    const _quality = quality ? quality.label : ""
    try {
      dispatch(SetLoading(true))
      const res = await getAllProduct(selectedProductType, _color, _standard, _length, _texture, '', _quality, sort)
      setProducts(res.data)
    } catch (error) {
    } finally {
      dispatch(SetLoading(false))
    }
  }

  useEffect(() => {
    if (search) {
      fetchData("")
    }
    if (selectedProductType) {
      fetchData(selectedProductType)
    }
  }, [selectedProductType, reduxState.reducer?.filter, sort])

  const handleOpenFilter = () => {
    document.getElementById('backdrop').style.display = 'flex'
    document.getElementById('filter').style.width = "90vw"
  }

  const handleOnClick = (item) => {
    navigate(`/product/details?productId=${item.id}`);
  };

  const fetchSupporterData = async () => {
    try {
      const res = await getSaleTeam();
      setSupporterData(res.data);
    } catch (error) {
      toast({
        title: "Api error",
        description: getErrorMessage(error),
        status: "error",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    fetchSupporterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Container
        bg="#ffff"
        p={isMobile ? 0 : 2}
        maxW={isMobile ? "100%" : "1600px"}
        mt={["60px", "65px", "50px", "50px", "50px"]}
        sx={{
          mb: "20px",
          minHeight: "90vh !important",
          mr: "auto",
          ml: "auto",
          position: "relative",
          zIndex: 2,
          // pb: "30px",
        }}
      >
        <Box className="product-main" mt={0}>
          {!isMobile ? (
            <Box minHeight="100vh !important">
              {search ? <Box className="product-title"> All Products </Box> : <Box className="product-title"> {reduxState && `${reduxState.reducer.selectedFilter.parent}`}</Box>}

              <Grid templateColumns={"repeat(13,1fr)"}>
                <GridItem colSpan={3} >
                  {filterOptions && <Filter sx={{ marginTop: "20px" }} data={filterOptions} setSort={setSort} sort={sort} />}
                </GridItem>
                <GridItem colSpan={10}>
                  <Box>
                    {!search && <div className="toolbar" id="toolbar">
                      <Tabs index={tab} onChange={(e) => { setTab(e) }} >
                        <TabList>
                          {
                            reduxState.reducer.childItem?.map((i) => {
                              return (
                                <Tab
                                  className="had-dropdown"
                                  pl={2}
                                  pr={2}
                                  _selected={{ color: "#d4af37", fontWeight: "bold", borderBottom: "2px solid #d4af37" }}
                                  id={i.id}
                                  onClick={() => { setSelectedProductType(i.attributes.name) }}
                                >
                                  {i.attributes.name}
                                </Tab>
                              )
                            })
                          }
                        </TabList>
                      </Tabs>
                    </div>}

                    <Grid
                      mt={2}
                      templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)", "repeat(4, 1fr)"]}
                    >
                      {
                        products.map((item, index) => {
                          return (
                            <GridItem sx={{ display: "flex", mx: "auto" }} colSpan={1} key={index}>
                              <ProductCard
                                sx={{
                                  mb: 1,
                                  mx: "auto",
                                }}
                                isView
                                key={index}
                                // isBestSelling={item.isBestSelling}
                                title={item.attributes.name}
                                thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                                // images={item.imageUrls}
                                // subtitle={item.productTypeName}
                                price={item.attributes.default_price}
                                onClick={() => {
                                  handleOnClick(item);
                                }}
                              />
                            </GridItem>
                          );
                        })
                      }
                    </Grid>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          ) : (
            <>
              <Flex justifyContent="space-between">
                {search ? <Box sx={{ fontSize: "15px !important", ml: 1 }} className="product-title"> All Products </Box> : <Box sx={{ fontSize: "15px !important", ml: 1 }} className="product-title"> {reduxState && `${reduxState.reducer.selectedFilter.parent}`}</Box>}
                <button onClick={() => { handleOpenFilter() }} className="btn-filter">Filter & Sort <FaFilter style={{ marginLeft: "2px" }} /></button>
              </Flex>
              {!search && <div className="toolbar" id="toolbar">
                <Tabs index={tab} onChange={(e) => { setTab(e) }} >
                  <TabList>
                    {
                      reduxState.reducer.childItem?.map((i) => {
                        return (
                          <Tab
                            className="had-dropdown"
                            pl={2}
                            pr={2}
                            _selected={{ color: "#d4af37", fontWeight: "bold", borderBottom: "2px solid #d4af37" }}
                            id={i.id}
                            onClick={() => { setSelectedProductType(i.attributes.name) }}
                            fontSize="12px"
                          >
                            {i.attributes.name}
                          </Tab>
                        )
                      })
                    }
                  </TabList>
                </Tabs>
              </div>}

              <Grid
                mt={2}
                templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)", "repeat(4, 1fr)"]}
              >
                {
                  products.map((item, index) => {
                    return (
                      <GridItem sx={{ display: "flex", mx: "auto" }} colSpan={1} key={index}>
                        <ProductCard
                          sx={{
                            mb: 1,
                            mx: "auto",
                          }}
                          isView
                          key={index}
                          // isBestSelling={item.isBestSelling}
                          title={item.attributes.name}
                          thumbImage={item.attributes.images.data && item.attributes.images.data[0].attributes.url}
                          // images={item.imageUrls}
                          // subtitle={item.productTypeName}
                          price={item.attributes.default_price}
                          onClick={() => {
                            handleOnClick(item);
                          }}
                        />
                      </GridItem>
                    );
                  })
                }
              </Grid>
            </>
          )}
        </Box>
        <FilterMobile data={filterOptions} />
        <SupportSection isMobile={isMobile} data={supporterData} />
      </Container>

    </>
  );
};

const ProductFilter = ({ }) => {
  return (
    <div>

    </div>
  )
}


export default Products;
