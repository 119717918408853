import { Image } from '@chakra-ui/react'
import React from 'react'

const ColorCard = ({ color, onClick, image }) => {
    return (
        <div className='color-container'>
            <Image className='color-wrapper' id={`color-${color}`} aria-selected="false" onClick={(e) => { onClick(e, color) }} src={`${process.env.REACT_APP_IMAGE_URL}${image}`} sx={{ h: "50px", w: "50px", borderRadius: "10px", position: "asolute", p: 1, border: "2px solid #eceff1" }} />
            <span>{`${color}`}</span>
        </div>
    )
}

export default ColorCard