import { getOrderDetailsById } from '@api/main'
import { Button, Image, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import './CartStep3.scss'

const CartStep3 = ({ orderId }) => {
    const [data, setData] = useState()

    const fetchData = async () => {
        const res = await getOrderDetailsById(orderId)
        setData(res.data)
    }

    useEffect(() => {
        if (orderId) {
            fetchData()
        }
    }, [orderId])

    const goToDetails = () => {
        window.location.href = `/order-details?uuid=${orderId}`
    }

    return (
        <>
            <div className='header-text'>
                <Image
                    src='/icons/tick.svg'
                    h="120px"
                    sx={{ display: "flex", ml: "auto", mr: "auto", mb: 5, mt: 5 }}
                />
                <Text textTransform="uppercase" fontSize="25px" fontWeight='bold'>
                    Your order is confirmed
                </Text>
                <Text>
                    Thank you for your order !
                </Text>
                <Text fontSize="15px">
                    Order ID: {data?.attributes.uuid}
                </Text>
                <button onClick={() => { goToDetails() }}>
                    <Text fontSize="13px" textDecoration="underline">
                        Click here to see your order
                    </Text>
                </button>

            </div>
            <Button
                _hover={{
                    boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                }}
                variant="solid"
                bg="tomato"
                color="#ffff"
                onClick={() => { window.location.href = "/" }}
                sx={{ borderRadius: "none", display: "flex", ml: "auto", mr: "auto", mt: 5 }}
            >
                Back to shopping
            </Button>

        </>
    )
}

export default CartStep3