import { Box, Container, Image } from "@chakra-ui/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ResponsiveBanner, ResponsiveBanner2, ResponsiveImage } from "src/theme/ResponsiveTheme";
// responsive

const ImageSlider = ({ images, promoteImage }) => {

  return (
    <Container p={0} maxW={["100%", "100%", "100%", "100%", "100%"]} h={ResponsiveImage}>
      <Box mt={["0px", "60px", "113px", "65px", "65px"]} w="100%" h={["128px", "auto", "auto", "100%", "100%"]}>
        <Carousel
          showThumbs={false}
          infiniteLoop
          autoPlay
          showArrows={false}
          showStatus={false}
          interval={4000}
          className="slide-container"
        >
          {images.length > 0 && images?.map((i, index) => (
            <Box key={index} sx={{ position: "relative", color: "#ffff", textAlign: "center" }}>
              <Image
                src={`${process.env.REACT_APP_IMAGE_URL}${i.attributes.picture.data.attributes.formats.banner.url}`}
                h={ResponsiveBanner}
                sx={{ width: "100%", maxHeight: "700px", objectFit: "cover" }}
              />
            </Box>
          ))}
        </Carousel>
        <Image
          src={`${process.env.REACT_APP_IMAGE_URL}${promoteImage[0]?.attributes.picture.data.attributes.formats.banner.url}`}
          h={ResponsiveBanner2}
          sx={{ width: "100%", objectFit: "cover", backgroundSize: "100% 100%" }}
        />
      </Box>
    </Container>
  );
};

export default ImageSlider;
