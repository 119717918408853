import { Container, Grid, GridItem, Text, useToast } from '@chakra-ui/react'
import CartItem from '@components/CartItem';
import useMobile from '@hooks/useMobile';
import { handleDecreaseCartAC, handleDeleteCartAC, handleIncreaseCartAC, handleRemoveCartAC } from '@store/action';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi'
import { RiDeleteBinLine } from 'react-icons/ri'
// import './CartItem.scss'
import './Cart.scss'
import { Step, Stepper } from 'react-form-stepper';
import CartStep2 from './CartStep2';
import { FormattedMessage } from 'react-intl';
import CartStep3 from './CartStep3';

const Cart = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const [isMobile] = useMobile();
    const cartState = useSelector((state) => state.cartReducer)
    const [step, setStep] = useState(0)
    const [orderId, setOrderId] = useState()

    const handleIncrease = (index) => {
        dispatch(handleIncreaseCartAC(index))
    }

    const handleDecrease = (index) => {
        dispatch(handleDecreaseCartAC(index))
    }

    const handleRemoveItem = (index) => {
        dispatch(handleDeleteCartAC(index))
    }

    const handleDeleteAllCart = () => {
        dispatch(handleRemoveCartAC())
        toast({
            description: "Delete cart success !",
            status: 'success',
            duration: 1000
        })
    }

    // action 
    const toStep2 = () => {
        setStep(1)
    }

    const goToStep3 = (data) => {
        setStep(2)
        setOrderId(data)
    }

    let totalCartPrice = 0;

    cartState.carts?.forEach((i) => {
        totalCartPrice = totalCartPrice + (i.amount * i.quantity * 10);
    });

    return (
        <div>
            <Container
                p={isMobile ? 0 : 2}
                maxW={isMobile ? "100%" : "1500px"}
                mt={["70px", "50px", "50px", "70px", "80px"]}
                sx={{
                    minHeight: "60vh !important",
                    mr: "auto",
                    ml: "auto",
                    position: "relative",
                    zIndex: 2,
                    pb: "30px",
                }}
                className="container"
            >
                <div>
                    <Stepper className='stepper' activeStep={step}>
                        <Step label={<Text fontWeight="bold" fontSize={15}>
                            <FormattedMessage id="shopping-cart" />
                        </Text>} />
                        <Step label={<Text fontWeight="bold" fontSize={15}>
                            <FormattedMessage id="checkout" />
                        </Text>} />
                        <Step label={<Text fontWeight="bold" fontSize={15}>
                            <FormattedMessage id="complete" /></Text>} />
                    </Stepper>
                </div>
                {step === 0 && <Grid templateColumns={["repeat(2,1fr)", "repeat(2,1fr)", "repeat(10,1fr)", "repeat(10,1fr)", "repeat(10,1fr)"]} gap={[0, 0, 0, 10, 10]}>
                    <GridItem colSpan={[2, 2, 7, 7, 7]}>
                        {/* <div className='item-card'>
                            <div className='card-header'>
                                Your Order
                            </div>
                        </div> */}
                        {!isMobile ? <div className='flex-header'>
                            <div className='product-header'>
                                Product
                            </div>
                            <div className='flex'>
                                <div>
                                    Description
                                </div>
                                <div>
                                    Quantity
                                </div>
                            </div>
                            <div>
                                Price
                            </div>
                        </div> : <div className='flex-header'>
                            {/* <div className='product-header'>
                                Product
                            </div>
                            {/* <div className='flex'>
                                <div>
                                    Description
                                </div>
                                <div>
                                    Quantity
                                </div>
                            </div>
                            <div>
                                Price
                            </div> */}
                        </div>}

                        {cartState.carts?.map((i, idx) => {
                            return (
                                <>
                                    {
                                        !isMobile ?
                                            <>
                                                <div className='cart-item'>
                                                    <div style={{ display: 'flex', minWidth: "300px" }}>
                                                        <img className='img' src={i.image} alt=""></img>

                                                        <div className='content'>
                                                            <Text fontSize={15}>
                                                                {i.productName}
                                                            </Text>
                                                            <Text fontSize={13}>
                                                                {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.amount)}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <div className='flex'>
                                                        <div className='desciption' >
                                                            <Text>
                                                                <FormattedMessage id="standard" />: {i?.quality.label}
                                                            </Text>
                                                            <Text>
                                                                <FormattedMessage id="label.length" />: {i?.length.label}
                                                            </Text>
                                                            <Text>
                                                                <FormattedMessage id="label.color" />: {i?.color}
                                                            </Text>
                                                        </div>
                                                        <div className='flex-box'>
                                                            <div className='flex-box quantity'>
                                                                <button>
                                                                    <HiMinusSm className='minus' onClick={() => { handleDecrease(idx) }} />
                                                                </button>
                                                                {i.quantity <= 0 ? <RiDeleteBinLine className='minus' onClick={() => { handleRemoveItem(idx) }} /> : <Text fontSize={14} >
                                                                    {i.quantity.toFixed(1)}
                                                                </Text>}
                                                                <button>
                                                                    <HiPlusSm className='plus' onClick={() => { handleIncrease(idx) }} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Text fontSize={15}>
                                                        {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.quantity * i.amount * 10)}
                                                    </Text>
                                                </div>
                                            </> :
                                            <div className='cart-item-m'>
                                                <div style={{ display: 'flex', }}>
                                                    <div>
                                                        <img className='img' src='/images/about_us_picture.png' alt=""></img>
                                                        <div className='flex'>
                                                            <div className='flex-box'>
                                                                <div className='flex-box quantity'>
                                                                    <HiMinusSm className='minus' onClick={() => { handleDecrease(idx) }} />
                                                                    {i.quantity <= 0 ? <RiDeleteBinLine className='minus' onClick={() => { handleRemoveItem(idx) }} /> : <Text fontSize={14} >
                                                                        {i.quantity}
                                                                    </Text>}
                                                                    <HiPlusSm className='plus' onClick={() => { handleIncrease(idx) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='content'>
                                                        <Text fontSize={15}>
                                                            {i.productName} - {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.amount)}
                                                        </Text>
                                                        {/* <Text fontSize={13}>
                                                            {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.price)}
                                                        </Text> */}

                                                        <div className='desciption' >
                                                            <Text fontSize={14}>
                                                                <FormattedMessage id="standard" />: {i?.quality.label}
                                                            </Text>
                                                            <Text fontSize={14}>
                                                                <FormattedMessage id="label.length" />: {i?.length.label}
                                                            </Text>
                                                            <Text fontSize={14}>
                                                                <FormattedMessage id="label.color" />: {i?.color}
                                                            </Text>
                                                        </div>

                                                    </div>

                                                </div>

                                                {/* <Text fontSize={15}>
                                                {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(i.quantity * i.price)}
                                            </Text> */}
                                            </div>
                                    }</>
                            )
                        })}
                    </GridItem>
                    <GridItem colSpan={3} >
                        <div className={!isMobile ? "checkout-section" : "checkout-section-m"}>
                            <div className='subtotal-container' >
                                <Text color="#8c9aa9">
                                    <FormattedMessage id="subtotal" />:
                                </Text>
                                <Text fontWeight="bold">
                                    {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(totalCartPrice)}
                                </Text>
                            </div>
                            <div className='subtotal-container' >
                                <Text color="#8c9aa9">
                                    <FormattedMessage id="discount" />:
                                </Text>
                                <Text fontWeight="bold">
                                    $0
                                </Text>
                            </div>
                            <div className='grand-total'>
                                <Text>
                                    <FormattedMessage id="total" />:
                                </Text>
                                <Text fontWeight="bold">
                                    {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(totalCartPrice)}
                                </Text>
                            </div>
                            <button className='btn-checkout' onClick={() => { toStep2() }}>
                                <FormattedMessage id="btn-checkout" />
                            </button>
                        </div>
                        <button className='btn-delete-all' onClick={() => { handleDeleteAllCart() }}>
                            <RiDeleteBinLine /><Text ml={1}> <FormattedMessage id="btn-delete-cart" /></Text>
                        </button>

                        {/* <button className='btn-delete-all' onClick={() => { handleDeleteAllCart() }}>
                                <RiDeleteBinLine /><Text ml={1}> <FormattedMessage id="btn-delete-cart" /></Text>
                            </button> */}

                    </GridItem>
                </Grid>}
                {step === 1 && <CartStep2 setStep={setStep} cartState={cartState} goToStep3={goToStep3} />}
                {step === 2 && <CartStep3 setStep={setStep} cartState={cartState} orderId={orderId} />}
            </Container>
        </div >
    )
}

export default Cart