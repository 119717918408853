import { getFeedBack, getProductDetails, getProductPrice, postFeedBack } from "@api/main";
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import useMobile from "@hooks/useMobile";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'

import './ProductDetails.scss'
import ColorCard from "@components/ProductFilter/ColorCard";
import { useDispatch } from "react-redux";
import { handleAddCartAC, SetLoading } from "@store/action";
import FilterItem from "@components/ProductFilter/FilterItem";
import ReactStars from "react-rating-stars-component";
import ContactModal from "./ContactModal";
import { format } from "date-fns";

const ProductDetails = () => {
  const toast = useToast();
  const dispatch = useDispatch()
  const initLang = localStorage.getItem("language");
  const [isMobile] = useMobile();
  const navigate = useNavigate();
  const query = useLocation().search;
  const productId = new URLSearchParams(query).get("productId");
  const [color, setColor] = useState()
  const [quality, setQuality] = useState()
  const [length, setLength] = useState()
  const [data, setData] = useState();
  const [imageIndex, setImageIndex] = useState(0);
  const [weight, setWeight] = useState(0.1)
  const [total, setTotal] = useState(0)
  const [input, setInput] = useState({})
  const [repInput, setRepInput] = useState({})
  const [action, setAction] = useState("click")

  const [rep, setRep] = useState("")
  const [fb, setFb] = useState()
  const [open, setOpen] = useState(false)
  // const [arr, setArr] = useState([])

  const [price, setPrice] = useState([])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const btnSendStatus = input.name && input.email && input.review && input.rating && isValidEmail(input.email)

  const btnRepSendStatus = repInput.name && repInput.email && repInput.review && isValidEmail(repInput.email)



  const handleChangeInput = (e, type) => {
    const field = type !== "rating" ? e.target.name : "rating"

    switch (field) {
      case "name":
        setInput({ ...input, name: e.target.value })
        break;
      case "email":
        setInput({ ...input, email: e.target.value })
        break;
      case "review":
        setInput({ ...input, review: e.target.value })
        break;
      case "rating":
        setInput({ ...input, rating: e })
        break;
      default:
        break;
    }
  }

  const handleChangeRepInput = (e, type) => {
    const field = type !== "rating" ? e.target.name : "rating"
    switch (field) {
      case "name":
        setRepInput({ ...repInput, name: e.target.value })
        break;
      case "email":
        setRepInput({ ...repInput, email: e.target.value })
        break;
      case "review":
        setRepInput({ ...repInput, review: e.target.value })
        break;
      default:
        break;
    }
  }

  const handleSubmit = async () => {
    const formData = {
      name: input.name,
      email: input.email,
      comment: input.review,
      rating: input.rating,
      product: productId,
    }
    try {
      await postFeedBack({ data: formData })
      toast({
        description: "Thank you for your review !",
        status: "success",
        duration: 1000,
      });
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitReply = async (data, name) => {
    const formData = {
      name: repInput.name,
      email: repInput.email,
      comment: repInput.review,
      product: productId,
      reply_to: String(data),
      feed_backs: [],
      mention: name
    }
    try {
      await postFeedBack({ data: formData })
      toast({
        description: "Thank you for your review !",
        status: "success",
        duration: 1000,
      });
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  }

  const fetchData = async (productId) => {
    if (!!productId) {
      try {
        dispatch(SetLoading(true))
        const res = await getProductDetails(productId);
        setData(res.data);
        const fbRes = await getFeedBack(productId)

        setFb(fbRes.data)

      } catch (error) { } finally { dispatch(SetLoading(false)) }
    }

  };

  useEffect(() => {
    fetchData(productId, initLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  //* actions
  const fetchPrice = async () => {
    const res = await getProductPrice(productId, color, quality?.label, length?.label)
    setPrice(res.data)
  }

  useEffect(() => {
    if (!btnStatus()) {
      fetchPrice()
      setWeight(0.1)
    }
  }, [color, quality, length])

  //* handler
  const increaseWeight = () => {
    setWeight(weight + 0.1)
  }

  const decreaseWeight = () => {
    if (weight > 0) {
      setWeight(weight - 0.1)
    }
  }

  const handleWeightChange = (e) => {
    setWeight(Number(e.target.value))
  }

  useEffect(() => {
    if (data?.attributes.default_price) {
      setTotal(data?.attributes.default_price * Number(Number(weight).toFixed(1) * 10).toFixed(1))
    }
    if (price.length > 0) {
      setTotal(Number(Number(weight).toFixed(1) * 10).toFixed(1) * price[0].attributes.price)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, color, quality, length, price])

  const handleColorFilter = (e, value) => {
    const eleColor = document.querySelectorAll(".color-wrapper");
    eleColor.forEach((btn) => {
      btn.setAttribute('aria-selected', 'false');
    })
    e.target.setAttribute('aria-selected', 'true');
    setColor(value)
  }

  const handleQualityFilter = (e, value) => {
    const ele = document.querySelectorAll(".quality-box");
    ele.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
    e.target.setAttribute('aria-selected', 'true')
    setQuality(value)
  }

  const handleLengthFilter = (e, value) => {
    const ele = document.querySelectorAll(".filter-box");
    ele.forEach((e) => {
      e.setAttribute('aria-selected', 'false')
    })
    e.target.setAttribute('aria-selected', 'true')
    setLength(value)
  }

  // redux actions

  const handleAddCart = (data, nav) => {
    try {
      dispatch(handleAddCartAC({
        amount: price.length > 0 ? price[0].attributes.price : data?.attributes.default_price,
        productId: productId,
        productName: data?.attributes.name,
        image: `${process.env.REACT_APP_IMAGE_URL}${data?.attributes.images.data[imageIndex].attributes.url}`,
        price: total,
        quantity: Number(weight),
        quality: quality,
        length: length,
        color: color,

      }))
      toast({
        description: "Add item to your cart",
        status: "success",
        duration: 1000,
      });
    } catch (error) {
      toast({
        description: error,
        status: "error",
        duration: 1000,
      });
    }
    if (nav) {
      navigate('/cart')
    }
  }

  const btnStatus = () => {
    return quality && color && length ? false : true
  }

  // 
  return (
    <>
      <Container
        bg="#ffff"
        p={2}
        maxW={isMobile ? "100%" : "1400px"}
        mt={[0, "50px", "50px", "80px", "80px"]}
        sx={{
          mb: "20px",
          minHeight: "90vh !important",
          mr: "auto",
          ml: "auto",
          position: "relative",
          zIndex: 1,
          // boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          pb: "30px",
        }}
      >
        <Box mt={5} pb={10} sx={{ borderBottom: "2px solid #000" }}>
          <Grid templateColumns={["repeat(2,1fr)", "repeat(6,1fr)", "repeat(6,1fr)", "repeat(6,1fr)", "repeat(6,1fr)"]}>
            <GridItem colSpan={[2, 4, 4, 3, 3]}>
              <div className="flex-container">
                {!isMobile &&
                  <VStack VStack justifyContent="space-between" >
                    {data?.attributes.images.data.slice(0, 4).map((image, index) => {
                      return (
                        <Image
                          onClick={() => {
                            setImageIndex(index);
                          }}
                          sx={{
                            boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                            cursor: "pointer",
                            border: "1px solid #AAAAAA",
                          }}
                          key={index}
                          w={["40px", "50px", "50px", "90px", "90px"]}
                          h={["40px", "50px", "50px", "90px", "90px"]}
                          // src={image}
                          src={`${process.env.REACT_APP_IMAGE_URL}${image.attributes.url}`}
                        />
                      );
                    })}
                  </VStack>
                }
                <Image
                  sx={{ boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);" }}
                  w={["390px", "390px", "460px", "460px", "500px"]}
                  h={["390px", "390px", "460px", "460px", "500px"]}
                  src={`${process.env.REACT_APP_IMAGE_URL}${data?.attributes.images.data[imageIndex].attributes.url}`}
                // src="/images/about_us_picture.png"

                />
              </div>
              {!isMobile && <>
                {/* <div className="text-bold">
                  Desciption
                </div> */}
                <div className="content-container">
                  {data?.attributes.description}
                </div>
              </>}
            </GridItem>
            <GridItem colSpan={[2, 3, 3, 3, 3]} sx={!isMobile && { paddingLeft: "30px", paddingRight: "80px" }}>
              <VStack alignItems="flex-start" p={3} pt={0} sx={{ borderBottom: "1px solid #eceff1" }}>
                <Text fontSize={["20px", "20px", "26px", "30px", "30px"]} fontWeight="bold">
                  {data?.attributes.name}
                  {/* Luxury Straight Light Color Double Layer Silky Flat Weft Hair */}
                </Text>
                <Text fontSize={["20px", "20px", "26px", "28px", "28px"]} >
                  {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(price.length > 0 ? price[0].attributes.price : data?.attributes.default_price)}/0.1kg
                </Text>
              </VStack>
              <VStack alignItems="flex-start" spacing={4} p={3} pb={0}>
                <VStack alignItems="flex-start" spacing="10px" sx={{ borderBottom: "1px solid #eceff1", paddingBottom: "10px" }}>
                  <HStack>
                    <Text>
                      <b>
                        Origin:
                      </b>{" "}
                      {data?.attributes.product_origin.data.attributes.name}
                    </Text>
                    <Text></Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold">
                      Quality:
                    </Text>
                    <Text>
                      Inch
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold">
                      <FormattedMessage id="label.hairStyle" />:{" "}
                    </Text>
                    <Text>
                      {data?.attributes.product_texture.data.attributes.name}
                    </Text>
                  </HStack>
                </VStack>
                <div className='section'>
                  <HStack>
                    <Text mb={3} fontWeight="bold">
                      Length:
                    </Text>
                  </HStack>
                  <div className="section-filter">
                    {data?.attributes.product_lengths.data.map((v, index) => {
                      return (
                        <FilterItem key={index} onClick={handleLengthFilter} label={v.attributes.value} value={v.id} />
                      )
                    })}
                  </div>
                </div>
                <div>
                  <HStack>
                    <Text mb={3} fontWeight="bold">
                      <FormattedMessage id="label.color" />:
                    </Text>
                    {/* <Text>{data?.colorName}</Text> {data?.colorCode.length === 1 && <Text>{data?.colorCode[0]}</Text>} */}
                  </HStack>
                  <div className='section-filter'>
                    {data?.attributes.colors.map((v, index) => {
                      return (
                        <ColorCard image={v.image.data.attributes.url} color={v.code} key={index} onClick={handleColorFilter} />
                      )
                    })}
                  </div>
                </div>
                <div>
                  <HStack>
                    <Text mb={3} fontWeight="bold">
                      <FormattedMessage id="label.standard" />:
                    </Text>
                  </HStack>
                  <Flex>
                    {data?.attributes.product_standards.data.map((v, index) => {
                      return (
                        <FilterItem className="quality-box" key={index} onClick={handleQualityFilter} label={v.attributes.name} />
                      )
                    })}
                  </Flex>
                </div>
                <div className="flexx-container">
                  <div className="weight-container">
                    <Text fontWeight="bold">
                      <FormattedMessage id="label.weight" /> (kg):
                    </Text>
                    <div class="quantity">
                      <button>
                        <AiOutlineMinusSquare onClick={() => { decreaseWeight() }} style={{ width: "40px", height: "40px" }} />
                      </button>
                      {action === "click" ? <Box sx={{ border: "1px solid #eee", padding: "6px 13px 2px 13px" }} onClick={() => { setAction("type") }}>
                        {Number(weight).toFixed(1)}
                      </Box> : <Input onBlur={() => { setAction("click"); setWeight(weight.toFixed(1)) }} onChange={(e) => { handleWeightChange(e) }} type="number" value={weight} size="sm" sx={{ width: "55px" }} />}
                      <button>
                        <AiOutlinePlusSquare onClick={() => { increaseWeight() }} style={{ width: "40px", height: "40px" }} />
                      </button>
                    </div>
                  </div>
                  {!isMobile && <>
                    <Button
                      _hover={{
                        boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                      }}
                      variant="solid"
                      bg="black"
                      color="#ffff"
                      onClick={() => { setOpen(true) }}
                      sx={{ ml: "30px", borderRadius: "none", mt: "20px" }}
                    >
                      Contact
                    </Button>
                    <Button
                      _hover={{
                        boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                      }}
                      variant="solid"
                      bg="tomato"
                      color="#ffff"
                      onClick={() => { handleAddCart(data) }}
                      sx={{ ml: "15px", borderRadius: "none", mt: "20px" }}
                      disabled={btnStatus()}
                    >
                      <FormattedMessage id="btn-add-cart" />
                    </Button>
                  </>}
                </div>
                {isMobile && <div>
                  <Button
                    _hover={{
                      boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                    }}
                    variant="solid"
                    bg="black"
                    color="#ffff"
                    onClick={() => { setOpen(true) }}
                    sx={{ borderRadius: "none", mt: "20px" }}
                  >
                    Contact
                  </Button>
                  <Button
                    _hover={{
                      boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                    }}
                    variant="solid"
                    bg="tomato"
                    color="#ffff"
                    onClick={() => { handleAddCart(data) }}
                    sx={{ ml: "15px", borderRadius: "none", mt: "20px" }}
                    disabled={btnStatus()}
                  >
                    <FormattedMessage id="btn-add-cart" />
                  </Button>
                </div>}
                <Text fontWeight="bold"><FormattedMessage id="total" />: {Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(total)} </Text>
                <Button
                  _hover={{
                    boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.27);",
                  }}
                  variant="solid"
                  bg="black"
                  color="#ffff"
                  disabled={btnStatus()}
                  onClick={() => { handleAddCart(data, true) }}
                  sx={{ ml: "30px", borderRadius: "none", w: "100%" }}
                >
                  <FormattedMessage id="btn-buy-now" />
                </Button>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
        <div className="stage-2">
          <Grid templateColumns="repeat(6,1fr)" mt={4}>
            <GridItem GridItem colSpan={[7, 7, 7, 7, 7]} >
              <div className="text-bold" style={{ textTransform: "uppercase" }}>
                Shipping & return
              </div>
              <div className="content-container">
                <Text fontWeight="bold"> 1. For Delivery time</Text>
                <Text>The time it takes for your order to reach your door depends on where you are in the world. An order will take 3-5 days to reach your shipping address. Talk to our sales agent to get an accurate estimated delivery time. Meanwhile, here is a table for quick reference.</Text>
                <Text fontWeight="bold"> 2. Payment method</Text>
                <Text>AndreaHair accepts various payment methods, including credit card, PayPal, Western Union, MoneyGram, and bank transfer. Customers can select their preferred payment method at checkout. The webpage also includes instructions on how to make a payment via each method.</Text>
              </div>
            </GridItem>
          </Grid>
        </div>

        {!isMobile ?
          <div className="feedback-section">
            <div className="text-bold" style={{ textTransform: "uppercase", fontSize: "24px", marginTop: "15px" }}>
              Reviews
            </div>
            <div className="feedback">
              <Avatar mr={5} />
              <div className="wrapper">
                <div className="flex">
                  <Input name="name" value={input?.name} mr={5} w="auto" placeholder="Your name..." onChange={(e) => { handleChangeInput(e) }} />
                  <Input name="email" type="email" value={input?.email} mr={5} w="auto" placeholder="Email..." onChange={(e) => { handleChangeInput(e) }} />
                </div>
                <ReactStars
                  count={5}
                  size={25}
                  value={input?.rating}
                  onChange={(e) => { handleChangeInput(e, "rating") }}
                />
                <div style={{ display: "flex", }}>
                  <Textarea value={input?.review} name="review" placeholder="Write your review" onChange={(e) => { handleChangeInput(e) }} />
                  <Button
                    variant="solid"
                    bg="tomato"
                    color="#ffff" h="100%"
                    ml={3}
                    onClick={() => { handleSubmit() }}
                    disabled={!btnSendStatus}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
            <div className="fb-content-wrapper">
              {fb?.map((i, idx) => {
                const handleReply = () => {
                  setRep(i.id)
                }
                if (i.attributes.reply_to.data === null || i.attributes.rating !== null) {
                  return (
                    <div key={idx} className="fb-content">
                      <Avatar mr={5} />
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", alignContent: "center" }} >
                          <Text fontWeight={700}>
                            {decodeURI(i.attributes.name)}{" "} <span style={{ fontWeight: "500" }}>{` (${format(Date.parse(i.attributes.createdAt), 'HH:mm:ss | yyyy-MM-dd')})`}</span>
                          </Text>

                        </div>

                        <Text>
                          {decodeURI(i.attributes.comment)}
                        </Text>
                        <Flex>
                          <ReactStars
                            count={5}
                            size={20}
                            isHalf={true}
                            value={i.attributes.rating}
                            edit={false}
                          />
                          <button onClick={() => { handleReply() }} style={{ marginLeft: "20px", textDecoration: "underline", color: "blue" }}>
                            Reply
                          </button>
                        </Flex>
                        {
                          rep === i.id &&
                          <div style={{ width: "100%" }}>
                            <div className="feedback">
                              <Avatar mr={5} />
                              <div className="wrapper">
                                <div className="flex">
                                  <Input name="name" value={repInput?.name} mr={5} w="auto" placeholder="Your name..." onChange={(e) => { handleChangeRepInput(e) }} />
                                  <Input name="email" value={repInput?.email} mr={5} w="auto" placeholder="Email..." onChange={(e) => { handleChangeRepInput(e) }} />
                                </div>
                                <div style={{ display: "flex", }}>
                                  <Textarea value={repInput?.review} name="review" placeholder="Write your reply..." onChange={(e) => { handleChangeRepInput(e) }} />
                                  <Button
                                    variant="solid"
                                    bg="tomato"
                                    color="#ffff" h="100%"
                                    ml={3}
                                    onClick={() => { handleSubmitReply(i.id, i.attributes.name) }}
                                    disabled={!btnRepSendStatus}
                                  >
                                    Send
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          i.attributes.feed_backs.data?.map((r) => {
                            const handleRepReply = () => {
                              setRep(r.id)
                            }
                            return (
                              <>

                                <div key={idx} className="fb-content">
                                  <Avatar mr={5} />
                                  <div style={{ width: "100%" }}>
                                    <div style={{ display: "flex", alignContent: "center" }} >
                                      <Text fontWeight={700}>
                                        {decodeURI(r.attributes.name)}{" "} <span style={{ fontWeight: "500" }}>{` (${format(Date.parse(r.attributes.createdAt), 'HH:mm:ss | yyyy-MM-dd')})`}</span>
                                      </Text>

                                    </div>

                                    <Text>
                                      <b style={{ textDecoration: "underline" }}>{r.attributes.mention}</b>{"  "}
                                      {decodeURI(r.attributes.comment)}
                                    </Text>
                                    <button onClick={() => { handleRepReply() }} style={{ textDecoration: "underline", color: "blue" }}>
                                      Reply
                                    </button>
                                  </div>
                                </div>
                                {
                                  rep === r.id &&
                                  <div style={{ width: "100%" }}>
                                    <div className="feedback">
                                      <Avatar mr={5} />
                                      <div className="wrapper">
                                        <div className="flex">
                                          <Input name="name" value={repInput?.name} mr={5} w="auto" placeholder="Your name..." onChange={(e) => { handleChangeRepInput(e) }} />
                                          <Input name="email" value={repInput?.email} mr={5} w="auto" placeholder="Email..." onChange={(e) => { handleChangeRepInput(e) }} />
                                        </div>
                                        <div style={{ display: "flex", }}>
                                          <Textarea value={repInput?.review} name="review" placeholder="Write your reply..." onChange={(e) => { handleChangeRepInput(e) }} />
                                          <Button
                                            variant="solid"
                                            bg="tomato"
                                            color="#ffff" h="100%"
                                            ml={3}
                                            onClick={() => { handleSubmitReply(i.id, r.attributes.name) }}
                                            disabled={!btnRepSendStatus}
                                          >
                                            Send
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </>
                            )
                          })
                        }

                      </div>
                    </div>
                  )
                }

              })}

            </div>
          </div>
          :
          <div className="feedback-section">
            <div className="feedback-m">
              <div className="text-bold" style={{ textTransform: "uppercase", fontSize: "24px", marginTop: "5px" }}>
                Reviews
              </div>
              <div className="wrapper">
                <div className="flex">
                  <Input name="name" value={input?.name} mr={1} w="auto" onChange={(e) => { handleChangeInput(e) }} placeholder="Your name..." />
                  <Input name="email" value={input?.email} mr={1} w="auto" placeholder="Email..." onChange={(e) => { handleChangeInput(e) }} />
                </div>
                <div style={{ display: "flex", }}>
                </div>
              </div>
              <ReactStars
                count={5}
                size={20}
                value={input?.rating}
                onChange={(e) => { handleChangeInput(e, "rating") }}
              />
              <Textarea value={input?.review} name="review" w="100%" placeholder="Write your review" onChange={(e) => { handleChangeInput(e) }} />
              <Button
                variant="solid"
                bg="tomato"
                color="#ffff"
                mt={2}
                onClick={() => { handleSubmit() }}
                disabled={!btnSendStatus}
              >
                Send
              </Button>
            </div>
            <div className="fb-content-wrapper">
              {fb?.map((i, idx) => {
                const handleReply = () => {
                  setRep(i.id)
                }
                if (i.attributes.reply_to.data === null || i.attributes.rating !== null) {
                  return (
                    <>
                      <div key={idx} className="fb-content">

                        <div style={{ width: "100%" }}>
                          <div style={{ display: "flex", alignContent: "center" }} >
                            <Text fontWeight={700}>
                              {decodeURI(i.attributes.name)}{" "} <span style={{ fontWeight: "500" }}>{` (${format(Date.parse(i.attributes.createdAt), 'HH:mm:ss | yyyy-MM-dd')})`}</span>
                            </Text>

                          </div>

                          <Text>
                            {decodeURI(i.attributes.comment)}
                          </Text>
                          <Flex>
                            <ReactStars
                              count={5}
                              size={20}
                              isHalf={true}
                              value={i.attributes.rating}
                              edit={false}
                            />
                            <button onClick={() => { handleReply() }} style={{ marginLeft: "20px", textDecoration: "underline", color: "blue" }}>
                              Reply
                            </button>
                          </Flex>
                          {
                            i.attributes.feed_backs.data?.map((r) => {
                              const handleRepReply = () => {
                                setRep(r.id)
                              }
                              return (
                                <>

                                  <div key={idx} className="fb-content">

                                    <div style={{ width: "100%" }}>
                                      <div style={{ display: "flex", alignContent: "center" }} >
                                        <Text fontWeight={700}>
                                          {decodeURI(r.attributes.name)}{" "} <span style={{ fontWeight: "500" }}>{` (${format(Date.parse(r.attributes.createdAt), 'HH:mm:ss | yyyy-MM-dd')})`}</span>
                                        </Text>

                                      </div>

                                      <Text>
                                        <b style={{ textDecoration: "underline" }}>{r.attributes.mention}</b>{"  "}
                                        {decodeURI(r.attributes.comment)}
                                      </Text>
                                      <button onClick={() => { handleRepReply() }} style={{ textDecoration: "underline", color: "blue" }}>
                                        Reply
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    rep === r.id &&
                                    <div style={{ width: "100%" }}>
                                      <div className="feedback">
                                        <Avatar mr={5} />
                                        <div className="wrapper">
                                          <div className="flex">
                                            <Input name="name" value={repInput?.name} mr={5} w="auto" placeholder="Your name..." onChange={(e) => { handleChangeRepInput(e) }} />
                                            <Input name="email" value={repInput?.email} mr={5} w="auto" placeholder="Email..." onChange={(e) => { handleChangeRepInput(e) }} />
                                          </div>
                                          <div style={{ display: "flex", }}>
                                            <Textarea value={repInput?.review} name="review" placeholder="Write your reply..." onChange={(e) => { handleChangeRepInput(e) }} />
                                            <Button
                                              variant="solid"
                                              bg="tomato"
                                              color="#ffff" h="100%"
                                              ml={3}
                                              onClick={() => { handleSubmitReply(i.id, r.attributes.name) }}
                                              disabled={!btnRepSendStatus}
                                            >
                                              Send
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </>
                              )
                            })
                          }

                        </div>

                      </div>
                      {
                        rep === i.id &&
                        <div style={{ width: "100%" }}>
                          <div className="feedback-m">

                            <div className="wrapper">
                              <div className="flex">
                                <Input name="name" value={input?.name} mr={1} w="auto" onChange={(e) => { handleChangeRepInput(e) }} placeholder="Your name..." />
                                <Input name="email" value={input?.email} mr={1} w="auto" placeholder="Email..." onChange={(e) => { handleChangeRepInput(e) }} />
                              </div>
                              <div style={{ display: "flex", }}>

                              </div>
                            </div>
                            <Textarea value={input?.review} name="review" w="100%" placeholder="Write your review" onChange={(e) => { handleChangeRepInput(e) }} />
                            <Button
                              variant="solid"
                              bg="tomato"
                              color="#ffff"
                              mt={2}
                              onClick={() => { handleSubmitReply(i.id, i.attributes.name) }}
                              disabled={!btnRepSendStatus}
                            >
                              Send
                            </Button>
                          </div>
                        </div>
                      }
                    </>
                  )
                }

              })}
            </div>

          </div>
        }

      </Container>
      {
        open && <ContactModal isOpen={open} onClose={() => { setOpen(false) }} productId={productId} />
      }
    </>
  );
};

export default ProductDetails;
